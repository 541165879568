import Vue from 'vue'
import VueRouter from 'vue-router'
import VueLodash from 'vue-lodash'
import VueMoment from 'vue-moment'
import DashboardPlugin from './dashboard-plugin'
import { getLocalStorage } from 'src/util/index.js'
import Swal from 'sweetalert2'

// Plugins
import App from './App.vue'

// router setup
import routes from './routes/routes'

// plugin setup
Vue.use(VueRouter)
Vue.use(VueLodash)
Vue.use(VueMoment)
Vue.use(DashboardPlugin)


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
})

router.beforeEach(async (to, from, next) => {
  let localStorageData = getLocalStorage('chengyoData')
  if ((localStorageData || {}).acl) {
    let acl = localStorageData.acl
    let auth = []
    for (let key in acl) {
      if(acl[key] === true) {
        auth.push(key)
      }
    }
    if (auth.indexOf(to.meta.acl) === -1 && to.path !== '/login') {
      router.go(-1)
    }
    let today = Math.round(new Date() / 1000)
    if (today > localStorageData.expireAt) {
      Swal.fire({
        title: '登入逾期，系統將自動登出，請重新登入！',
        icon: 'error'
      }).then(() => {
        router.go(-1)
        localStorage.removeItem('chengyoData')
      })
    }
  }
  next()
})

// function redirect () {
//   return '/'
// }

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
})
