import axios from 'axios'
import {getLocalStorage} from 'src/util/index.js'
import _ from 'lodash'

// NOTE: 網址最後沒有反斜線
const API_URL = 'https://cclinic-api.robiio.com/api'
//  const API_URL = 'https://hq.qlieer.com:9488/cclinic_dev/api'

export default {
  async login (data) {
    return sendAPI({
      uri: '/login',
      method: 'POST',
      data,
      useAuth: false
    })
  },
  async getMemberAvailable () {
    return sendAPI({
      uri: '/available_member_id',
      useAuth: true
    })
  },
  async getMember (offset, size) {
    return sendAPI({
      uri: size ? `/member/${offset}/${size}` : '/member',
      useAuth: true
    })
  },
  async createMember(data) {
    return sendAPI({
      uri: '/member',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async modifyMember(data, id) {
    return sendAPI({
      uri: `/member/${id}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async getMemberConsultation(memberID) {
    return sendAPI({
      uri: `/consultation/${memberID}`,
      useAuth: true
    })
  },
  async modifyMemberConsultation(data, id) {
    return sendAPI({
      uri: `/consultation/${id}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async deleteMemberConsultation(id) {
    return sendAPI({
      uri: `/consultation/${id}`,
      method: 'DELETE',
      useAuth: true
    })
  },
  async getMemberLog(memberID) {
    return sendAPI({
      uri: `/log/member/${memberID}`,
      useAuth: true
    })
  },
  async searchMember(data) {
    return sendAPI({
      uri: '/member_search',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async createMemberConsultation(data) {
    return sendAPI({
      uri: '/consultation',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getProduct(productUUID) {
    return sendAPI({
      uri: productUUID ? `/product/${productUUID}` : '/product',
      useAuth: true
    })
  },
  async modifyProduct(data, productUUID) {
    return sendAPI({
      uri: `/product/${productUUID}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async createProduct(data) {
    return sendAPI({
      uri: '/product',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async addProductFavorite(productUUID) {
    return sendAPI({
      uri: `/product_favo/${productUUID}`,
      method: 'PUT',
      useAuth: true
    })
  },
  async removeProductFavorite(productUUID) {
    return sendAPI({
      uri: `/product_favo/${productUUID}`,
      method: 'DELETE',
      useAuth: true
    })
  },
  async deleteProduct(productUUID) {
    return sendAPI({
      uri: `/product/${productUUID}`,
      method: 'DELETE',
      useAuth: true
    })
  },
  async createCategory(data) {
    return sendAPI({
      uri: '/product_category',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getCategory() {
    return sendAPI({
      uri: '/product_category',
      useAuth: true
    })
  },
  async modifyCategory(category_id, data) {
    return sendAPI({
      uri: `/product_category/${category_id}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async deleteCategory(category_id) {
    return sendAPI({
      uri: `/product_category/${category_id}`,
      method: 'DELETE',
      useAuth: true
    })
  },
  async productCategoryBatch(data) {
    return sendAPI({
      uri: '/product_category_batch',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getStaff() {
    return sendAPI({
      uri: '/staff',
      useAuth: true
    })
  },
  async createStaff(data) {
    return sendAPI({
      uri: '/staff',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async modifyStaff(data, staffUUID) {
    return sendAPI({
      uri: `/staff/${staffUUID}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async deleteStaff(staffUUID) {
    return sendAPI({
      uri: `/staff/${staffUUID}`,
      method: 'DELETE',
      useAuth: true
    })
  },
  async getUser(userUUID) {
    return sendAPI({
      uri: userUUID ? `/user/${userUUID}` : '/user',
      useAuth: true
    })
  },
  async createUser(data) {
    return sendAPI({
      uri: '/user',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async modifyUser(data, userUUID) {
    return sendAPI({
      uri: `/user/${userUUID}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async deleteUser(userUUID) {
    return sendAPI({
      uri: `/user/${userUUID}`,
      method: 'DELETE',
      useAuth: true
    })
  },
  async getAuthority() {
    return sendAPI({
      uri: '/log/authority',
      useAuth: true
    })
  },
  async updateAuthority(data, userUUID) {
    return sendAPI({
      uri: `/authority/${userUUID}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  async checkCloseAccount() {
    // data有資料：已開帳，無資料：未開帳
    return sendAPI({
      uri: '/check_close_account',
      useAuth: true
    })
  },
  // 產訂單
  async createSalesorder(data) {
    return sendAPI({
      uri: '/salesorder',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  // 修改訂單(未關帳訂單)
  async modifySalesorder(data, orderID) {
    return sendAPI({
      uri: `/unclosed_order/${orderID}`,
      method: 'PUT',
      data,
      useAuth: true
    })
  },
  // 退訂單
  async returnSalesorder(data) {
    return sendAPI({
      uri: '/salesreturn',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  // 未關帳訂單
  async getUnclosedOrder() {
    return sendAPI({
      uri: '/unclosed_order',
      useAuth: true
    })
  },
  // 開帳
  async openCloseOrder(data) {
    return sendAPI({
      uri: '/open_account',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getCloseOrder(time) {
    return sendAPI({
      uri: time ? `/close_account/${time}` : '/close_account_list',
      useAuth: true
    })
  },
  // 關帳
  async closeCloseOrder(data) {
    return sendAPI({
      uri: '/close_account',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  // 取得未關帳By Member
  async getReconcile() {
    return sendAPI({
      uri: '/reconcile',
      useAuth: true
    })
  },
  async getReportHistory(data) {
    return sendAPI({
      uri: '/report/history',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getReportDaily(data) {
    return sendAPI({
      uri: '/report/daily',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getReportSales(data) {
    return sendAPI({
      uri: '/report/sales',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getReportVoucher(data) {
    return sendAPI({
      uri: '/report/voucher_effective_list',
      method: 'POST',
      data,
      useAuth: true
    })
  },
  async getReportMember(offset, size, meberUUID) {
    return sendAPI({
      uri: `/report/member/${meberUUID}/${offset}/${size}`,
      useAuth: true
    })
  },
  async getProductRepeat(SKU) {
    return sendAPI({
      uri: `/product_repeat/${SKU}`,
      useAuth: true
    })
  }
}

async function sendAPI({method = 'GET', uri, data, useAuth = true}) {
  let headers = getHeaders(useAuth)
  let options = {
    url: API_URL + uri,
    method,
    data,
    headers
  }
  if (method === 'DELETE') {
    delete options.data
  }
  try {
    let resp = await axios(options)
    return resp.data
  } catch (error) {
    if (error.response) {
      if (error.response.data.status === 401) {
        return error.response.data
      }
    }
    return false
  }
}

function getHeaders (useAuth) {
  let headers = {
    'Content-Type': 'application/json'
  }
  let localStorageData = getLocalStorage('chengyoData')
  if (_.get(localStorageData, 'token', null)) {
    headers['X-API-KEY'] = localStorageData.token
  } else {
    window.location.href = '#/login'
  }
  if (!useAuth) {
    return
  }
  return headers
}