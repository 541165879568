<template>
  <div class="today-orders">
    <div class="card card-stats card-raised">
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-primary">
                  <i class="now-ui-icons business_money-coins"></i>
                </div>
                <h3 class="info-title">
                  <small>$</small>
                  <animated-number :value="renderSummary.totalAmount" :formatValue="formatter" :duration="500"></animated-number>
                </h3>
                <h6 class="stats-title">銷售總額</h6>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-primary">
                  <i class="now-ui-icons business_money-coins"></i>
                </div>
                <h3 class="info-title">
                  <small>$</small>
                  <animated-number :value="renderSummary.maxAmount" :formatValue="formatter" :duration="500"></animated-number>
                </h3>
                <h6 class="stats-title">最高消費金額</h6>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-primary">
                  <i class="now-ui-icons business_money-coins"></i>
                </div>
                <h3 class="info-title">
                  <small>$</small>
                  <animated-number :value="renderSummary.avgAmount" :formatValue="formatter" :duration="500"></animated-number>
                </h3>
                <h6 class="stats-title">平均消費金額</h6>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-default">
                  <i class="now-ui-icons files_paper"></i>
                </div>
                <h3 class="info-title">
                  <animated-number :value="renderSummary.salesOrderCount" :formatValue="formatter" :duration="500"></animated-number><small> 次</small>
                </h3>
                <h6 class="stats-title">交易次數</h6>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-info">
                  <i class="now-ui-icons business_bulb-63"></i>
                </div>
                <h3 class="info-title">
                  <animated-number :value="renderSummary.lastVisitDays" :formatValue="formatter" :duration="500"></animated-number><small> 天</small>
                </h3>
                <h6 class="stats-title">距上次回診日天數</h6>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="statistics">
              <div class="info">
                <div class="icon icon-default">
                  <i class="now-ui-icons ui-1_calendar-60"></i>
                </div>
                <div class="info-title">
                  <h6 class="stats-title text-left">初診日期：<br/>{{renderSummary.firstVisit}}</h6>
                  <h6 class="stats-title text-left mt-2">回診日期：<br/>{{renderSummary.currentVisit}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width" no-footer-line>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <h4 slot="header" class="card-title">{{renderInfo.name}}的銷售明細(會員編號：{{renderInfo.memberID}})</h4>
            <div class="align-self-center">
              <div class="d-flex justify-content-end">
                <button class="btn btn-info" @click.prevent="exportExcel" :disabled="computedExportDisabled"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出{{renderInfo.name}}銷售明細</button>
              </div>
            </div>
          </div>
          <!-- 歷史報表 -->
          <div class="col-12">
            <el-table header-cell-class-name="font-14" :data="tableByMember" row-key="mappingId" :tree-props="{children: 'saleDetails'}" default-expand-all :row-class-name="rendertableRowClassName">
              <el-table-column v-for="column in tableByMemberCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
                <template slot-scope="scope">
                  <div v-if="column.prop === 'totalSaleAmount'">
                    $ {{ formatter(scope.row[column.prop]) }}
                  </div>
                  <div v-else-if="column.prop === 'qty'">
                    {{ formatter(scope.row[column.prop]) }}
                  </div>
                  <div v-else-if="column.prop === 'salePrice'">
                    {{ scope.row[column.prop] ? '$' + formatter(scope.row[column.prop]) : scope.row[column.prop] }}
                  </div>
                  <span v-else-if="column.prop === 'tradeDate'">
                   {{ scope.row[column.prop] }}
                  </span>
                  <div v-else>{{ scope.row[column.prop] }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
           <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div>
              <p class="card-category"> 顯示 {{ from + 1 }} 至 {{ to }} ，總共有 {{ total }} 紀錄</p>
            </div>
            <n-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" @nextPage="nextPage" @changePage="changePage" @prevPage="prevPage"></n-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Select, Option, Button, DatePicker, Loading} from 'element-ui'
import { getLocalStorage } from 'src/util/index.js'
import { AnimatedNumber, Pagination as NPagination } from 'src/components'
import lang from 'element-ui/lib/locale/lang/zh-TW'
import locale from 'element-ui/lib/locale'
import moment from 'moment'
import XLSX from 'xlsx'
import {v1 as uuidv1} from 'uuid'
import _ from 'lodash'

locale.use(lang)

export default {
  components: {
    NPagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    AnimatedNumber
  },
  data() {
    return {
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      tableByMemberCols: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 180
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 120
        },
        {
          prop: 'SKU',
          label: '商品編號',
          minWidth: 80
        },
        {
          prop: 'name',
          label: '商品名稱',
          minWidth: 180
        },
        {
          prop: 'qty',
          label: '數量',
          minWidth: 80
        },
        {
          prop: 'salePrice',
          label: '單價',
          minWidth: 80
        },
        {
          prop: 'totalSaleAmount',
          label: '金額',
          minWidth: 100
        },
        {
          prop: 'staffName',
          label: '銷售人員',
          minWidth: 80
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一週',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          }
        ]
      },
      todayNewMember: 0,
      tableSummary: [],
      tableByMember: [],
      staff: []
    }
  },
  methods: {
    async init() {
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let result = await API.getReportMember(0, 100, this.$router.history.current.params.id)
      if (result.message === 'success') {
        this.tableSummary = result
        this.mappingData(result.data)
      }
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    async exportExcel () {
      let title = '歷史銷售明細'
      let sheetList = ['歷史銷售明細']
      let wb = XLSX.utils.book_new()
      for (let index = 0; index < sheetList.length; index++) {
       if (sheetList[index] === '歷史銷售明細') {
          let ws, resolveData
          let data = []
          let result = await API.getReportMember(0, this.tableSummary.totalData, this.$router.history.current.params.id)
          if (result.message === 'success') {
            _.map(result.data, r => {
              r.tradeDate = moment(r.tradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
              _.map(r.saleDetails, o => {
                o.tradeDate = r.tradeDate
                o.tradeID = r.tradeID
                // 報表因為是row data，需要參考折扣價格計算總價，無法列出單價，金額不是單純的數量*單價
                let count = o.qty / o.quantityDiscout
                o.mappingPrice = (count >= 1 && o.quantityDiscoutPrice) ? o.quantityDiscoutPrice : o.salePrice
                if (count >= 1) {
                  o.totalSaleAmount = Math.floor(count) * Number(o.mappingPrice) + (o.qty % o.quantityDiscout * Number(o.salePrice))
                } else {
                  o.totalSaleAmount = o.qty * Number(o.salePrice)
                }
                let { tradeDate, tradeID, SKU, name, qty, totalSaleAmount, staffName } = o
                data.push({ tradeDate, tradeID, SKU, name, qty, totalSaleAmount, staffName })
              })
            })
          }
          let header = [{tradeDate: '交易日期', tradeID: '交易單號', SKU: '商品編號', name: '商品名稱', qty: '數量', totalSaleAmount: '商品總額', staffName: '銷售人員'}]
          resolveData = header.concat(_.reverse(data))
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, sheetList[index])
        }
      }
      XLSX.writeFile(wb, title + `(${this.renderInfo.name})`  + '.xlsx')
    },
    async changePage (page) {
      let result = await API.getReportMember(page === 1 ? 0 : (page - 1) * this.pagination.perPage, this.pagination.perPage, this.$router.history.current.params.id)
      if (result.message === 'success') {
        this.mappingData(result.data)
      }
    },
    async prevPage (page) {
      let result = await API.getReportMember(page === 1 ? 0 : (page - 1) * this.pagination.perPage, this.pagination.perPage, this.$router.history.current.params.id)
      if (result.message === 'success') {
        this.mappingData(result.data)
      }
    },
    async nextPage (page) {
      let result = await API.getReportMember(page === 1 ? 0 : (page - 1) * this.pagination.perPage, this.pagination.perPage, this.$router.history.current.params.id)
      if (result.message === 'success') {
        this.mappingData(result.data)
      }
    },
    mappingData (data) {
      this.tableByMember = _.reverse(_.map(data, r => {
        r.SKU = ''
        r.name = ''
        r.qty = ''
        r.salePrice = ''
        r.staffName = ''
        r.tradeDate = moment(r.tradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
        r.mappingId = uuidv1()
        _.map(r.saleDetails, o => {
          // 報表因為是row data，需要參考折扣價格計算總價，無法列出單價，金額不是單純的數量*單價
          let count = o.qty / o.quantityDiscout
          o.mappingPrice = (count >= 1 && o.quantityDiscoutPrice) ? o.quantityDiscoutPrice : o.salePrice
          if (count >= 1) {
            o.totalSaleAmount = Math.floor(count) * Number(o.mappingPrice) + (o.qty % o.quantityDiscout * Number(o.salePrice))
          } else {
            o.totalSaleAmount = o.qty * Number(o.salePrice)
          }
          o.tradeID = ''
          o.tradeDate = ''
          o.mappingId = uuidv1()
          return o
        })
        return r
      }))
    },
    rendertableRowClassName ({row}) {
      if (_.get(row, 'returnOrderID', null)) {
        return 'table-danger'
      }
    },
    formatter (value) {
      return `${value.toLocaleString()}`
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.tableSummary.totalData
    },
    computedExportDisabled () {
      return this.tableByMember.length === 0
    },
    renderSummary () {
      let {totalAmount, maxAmount, avgAmount, salesOrderCount, lastVisitDays, firstVisit, currentVisit} = this.tableSummary
      return {totalAmount, maxAmount, avgAmount, salesOrderCount, lastVisitDays, firstVisit: moment(firstVisit, 'X').format('YYYY/MM/DD hh:mm:ss'), currentVisit: moment(currentVisit, 'X').format('YYYY/MM/DD hh:mm:ss')}
    },
    renderInfo () {
      let localStorageData = getLocalStorage('chengyoData')
      if (localStorageData.member) {
        return {name: localStorageData.member.name, memberID:  localStorageData.member.memberID}
      }
      return {name: '查無會員資料', memberID: '查無資料'}
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style></style>
