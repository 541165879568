<template>
  <div class="today-orders">
  <div class="card card-stats card-raised">
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_money-coins"></i>
              </div>
              <h3 class="info-title">
                <small>$ </small>
                <animated-number :value="renderSummary.totalSaleAmount" :formatValue="formatter" :duration="500"></animated-number>
              </h3>
              <h6 class="stats-title">期間營業總額<br></h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-default">
                <i class="now-ui-icons files_paper"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="renderSummary.total" :formatValue="formatter" :duration="500"></animated-number><small> 筆</small>
              </h3>
              <h6 class="stats-title">期間訂單總數</h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info">
                <i class="now-ui-icons users_single-02"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="renderSummary.member" :formatValue="formatter" :duration="500"></animated-number><small> 人</small>
              </h3>
              <h6 class="stats-title">回診會員總數，期間新增 ({{todayNewMember}}人）</h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-default">
                <i class="now-ui-icons ui-1_calendar-60"></i>
              </div>
              <h3 class="info-title">
                <div class="justify-content-end">
                  <el-date-picker
                    v-model="datePicker"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="開始日期"
                    end-placeholder="結束日期"
                    :picker-options="pickerOptions"
                    :default-time="['00:00:00', '23:59:59']"
                    value-format="timestamp"
                    @change="handleChange"
                  >
                  </el-date-picker>
                </div>
              </h3>
              <h6 class="stats-title">選擇查詢期間</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 slot="header" class="card-title">歷史報表紀錄</h4>
          <fg-input class="mx-3 mt-4 text-right">
            <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search" style="width: 400px" placeholder="搜尋交易編號、會員編號、會員姓名" v-model="searchQuery" aria-controls="datatables"></el-input>
          </fg-input>
          <div class="align-self-center">
            <div class="d-flex justify-content-end">
              <button class="btn btn-info" @click.prevent="exportExcel" :disabled="computedExportDisabled"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出歷史報表紀錄</button>
            </div>
          </div>
        </div>
        <!-- 歷史報表 -->
        <div class="col-12">
          <el-table header-cell-class-name="font-14" :data="queriedData" :row-class-name="rendertableRowClassName">
            <el-table-column v-for="column in tableByDayCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
              <template slot-scope="scope">
                <div v-if="column.prop === 'tradeID'">
                  <el-button type="text" class="outline-none font-16" style="color: #f96332;" @click.prevent="showOrderDetails(scope)">{{ scope.row[column.prop] }}</el-button>
                </div>
                <div v-else-if="column.prop === 'totalSaleAmount'">
                  $ {{ formatter(scope.row[column.prop]) }}
                </div>
                <div v-else>{{ scope.row[column.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
  </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Select, Option, Button, DatePicker, Loading } from 'element-ui'
import { getLocalStorage, setLocalStorage, renderMemberType, renderPaymentType, renderStoreType } from 'src/util/index.js'
import { AnimatedNumber } from 'src/components'
import Fuse from 'fuse.js'
import lang from 'element-ui/lib/locale/lang/zh-TW'
import locale from 'element-ui/lib/locale'
import moment from 'moment'
import XLSX from 'xlsx'
import _ from 'lodash'

locale.use(lang)

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    AnimatedNumber
  },
  watch: {
    searchQuery(value) {
      if (value !== '') {
        this.searchedData = this.fuseSearch.search(this.searchQuery).length === 0 ? [] : this.fuseSearch.search(this.searchQuery)
      } else {
        this.searchedData = []
      }
    }
  },
  data() {
    return {
      value: 1000,
      tableByDayCols: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 150
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 120
        },
        {
          prop: 'memberType',
          label: '會員級別',
          minWidth: 90
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 100
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 100
        },
        {
          prop: 'totalSaleAmount',
          label: '總金額',
          minWidth: 120
        },
        {
          prop: 'payment',
          label: '付款別',
          minWidth: 80
        },
        {
          prop: 'openTradeDate',
          label: '開帳時間',
          minWidth: 150
        },
        {
          prop: 'closeTradeDate',
          label: '關帳時間',
          minWidth: 150
        },
        {
          prop: 'branchID',
          label: '營業地點',
          minWidth: 80
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一週',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          }
        ]
      },
      todayNewMember: 0,
      searchQuery: '',
      searchedData: [],
      tableData: [],
      tableByDay: [],
      staff: [],
      datePicker: [],
      fuseSearch: null
    }
  },
  methods: {
    async init() {
      let localStorageData = getLocalStorage('chengyoData')
      if (_.values(_.get(localStorageData, 'datePicker', {})).length > 0) {
        this.datePicker.push(localStorageData.datePicker.startTime)
        this.datePicker.push(localStorageData.datePicker.endTime)
        this.handleChange()
      }
    },
    async handleChange () {
      if (this.datePicker === null) {
        this.tableByDay = []
        setLocalStorage('chengyoData', 'datePicker', {})
        return
      }
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let startTime = moment(this.datePicker[0]).format('X')
      let endTime = moment(this.datePicker[1]).format('X')
      let obj = {startTime, endTime}
      let result = await API.getReportHistory(JSON.stringify(obj))
      if (result.message === 'success') {
        this.todayNewMember = result.todayNewMember
        this.tableByDay = _.chain(result.data)
          .map(r => {
            r.tradeDate = moment(r.tradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
            r.openTradeDate = moment(r.openTradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
            r.closeTradeDate = moment(r.closeTradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
            r.memberType = renderMemberType(r.memberType)
            r.payment = renderPaymentType(r.payment)
            r.branchID = renderStoreType(r.branchID)
            _.map(r.saleDetails, o => {
              // 報表因為是row data，需要參考折扣價格計算總價，無法列出單價，金額不是單純的數量*單價
              let count = o.qty / o.quantityDiscout
              o.mappingPrice = (count >= 1 && o.quantityDiscoutPrice) ? o.quantityDiscoutPrice : o.salePrice
              if (count >= 1) {
                o.salePriceTotal = Math.floor(count) * Number(o.mappingPrice) + (o.qty % o.quantityDiscout * Number(o.salePrice))
              } else {
                o.salePriceTotal = o.qty * Number(o.salePrice)
              }
            })
            r.salePriceTotal = _.sumBy(r.saleDetails, 'salePriceTotal')
            return r
          })
        .reverse()
        .value()
      }
      this.fuseSearch = new Fuse(this.tableByDay, {
        keys: ['tradeID', 'memberName', 'memberID'],
        threshold: 0.1,
        includeScore: true
      })
      setLocalStorage('chengyoData', 'datePicker', {startTime: this.datePicker[0], endTime: this.datePicker[1]})
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    exportExcel () {
      let title = '歷史訂單列表'
      let sheetList = ['歷史訂單列表']
      let wb = XLSX.utils.book_new()
      let startTime = moment(this.datePicker[0], 'x').format('YYYY-MM-DD')
      let endTime = moment(this.datePicker[1], 'x').format('YYYY-MM-DD')
      _.forEach(sheetList, name => {
        if (name === '歷史訂單列表') {
          let ws, resolveData
          let data = _.map(this.tableByDay, r => {
            let {tradeDate, tradeID, memberID, memberName, memberType, totalSaleAmount, openTradeDate, closeTradeDate, branchID} = r
            return { tradeDate, tradeID, memberID, memberName, memberType, totalSaleAmount, openTradeDate, closeTradeDate, branchID}
          })
          let header = [{tradeDate: '交易日期', tradeID: '交易單號', memberID: '會員編號', memberName: '會員姓名', memberType: '會員級別', totalSaleAmount: '總金額', openTradeDate: '開帳時間', closeTradeDate: '關帳時間', branchID: '營業地點名稱'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        }
      })
      XLSX.writeFile(wb, title + `(${startTime} - ${endTime})`  + '.xlsx')
    },
    async searchMember (data) {
      let obj = {searchKey: data}
      let result = await API.searchMember(JSON.stringify(obj))
      if (result.message === 'success') {
        let consultation = await API.getMemberConsultation(result.data[0].id)
        result.data[0].consultation = _.chain(consultation.data)
          .reverse()
          .map(r => {
            r.lastDate = moment(r.date, 'X').format('YYYY-MM-DD')
            return r
          })
          .head()
          .value()
        return result
      }
    },
    async showOrderDetails (data) {
      let localStorageData = getLocalStorage('chengyoData')
      let result = await this.searchMember(data.row.memberID)
      if (localStorageData && result) {
        _.map(data.row.saleDetails, r => {
          r.originQty = r.qty
          r.originSalePrice = r.salePrice
          r.originRewardPrice = _.get(r, 'rewardPrice', 0)
          let count = r.qty / r.quantityDiscout
          r.mappingPrice = (count >= 1 && r.quantityDiscoutPrice) ? r.quantityDiscoutPrice : r.salePrice
          if (count >= 1) {
            r.totalAmount = Math.floor(count) * Number(r.mappingPrice) + (r.qty % r.quantityDiscout * Number(r.salePrice))
          } else {
            r.totalAmount = r.qty * Number(r.salePrice)
          }
          r.salesName = `(${r.staffID}) ${r.staffName}`
          return r
        })
        localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {order: data.row, member: result.data[0]})))
      }
      // 如果有退貨 ID (returnOrderID)，表示已退貨，不可以再修改訂單
      if (data.row.returnOrderID) {
        this.$router.push(`/returns/view/${data.row.returnOrderID}`) 
      } else {
        this.$router.push('/returns')
      }
    },
    rendertableRowClassName ({row}) {
      if (_.get(row, 'returnOrderID', null)) {
        return 'table-danger'
      }
    },
    formatter (value) {
      return `${value.toLocaleString()}`
    }
  },
  computed: {
    computedExportDisabled () {
      return !this.datePicker || this.datePicker.length === 0
    },
    queriedData () {
      let result = []
      if (this.searchedData.length > 0) {
        result = _.compact(_.map(this.searchedData, r => {
          if (r.score !== 0.1) {
            return r.item
          }
        }))
      } else {
        result = this.searchQuery.length === 0 ? this.tableByDay : []
      }
      return result.slice(this.from, this.to)
    },
    renderSummary () {
      let totalSaleAmount = _.chain(this.tableByDay)
        .sumBy('salePriceTotal')
        .value()
      let member = _.chain(this.tableByDay)
        .groupBy('memberID')
        .keys()
        .value()
      return {totalSaleAmount, member: member.length, total: this.tableByDay.length}
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss">
  .outline-none{
    &:focus{
      outline: none !important;
    }
  }
</style>
