function getLocalStorage(cname) {
  let localStorageData = JSON.parse(localStorage.getItem(`${cname}`))
  return localStorageData
}

function setLocalStorage(cname, key, value) {
  let localStorageData = JSON.parse(localStorage.getItem(`${cname}`))
  if (localStorageData && key !== undefined && value !== undefined) {
    let obj = {}
    obj[key] = value
    localStorage.setItem(`${cname}`, JSON.stringify(Object.assign(localStorageData, obj)))
  }
}

function renderMemberType(type) {
  switch (type) {
    case 'normalPrice':
      return '一般會員'
    case 'vipPrice':
      return 'VIP'
    case 'staffPrice':
      return '員工'
    case 'friendPrice':
      return '親屬關係'
    default:
      return '一般會員'
  }
}

function renderPaymentType(type) {
  switch (type) {
    case 'cash':
      return '現金'
    case 'creditCard':
      return '信用卡'
    case 'other':
      return '其他'
    default:
      return '現金'
  }
}

function renderStoreType(type) {
  switch (type) {
    case '1013':
      return '公益店'
    case '1015':
      return '永福店'
    default:
      return '其他店'
  }
}

export {
  getLocalStorage,
  setLocalStorage,
  renderMemberType,
  renderPaymentType,
  renderStoreType
}