<template>
  <div>
    <card card-body-classes="table-full-width">
       <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <h4 class="card-title">登入帳號管理</h4>
        <div class="align-self-center">
          <div class="d-flex justify-content-end" v-if="acl.auth">
            <button class="btn btn-primary btn-round mx-2" @click.prevent="handleAction('authority')"><i class="now-ui-icons objects_key-25"></i> 帳號權限管理</button>
            <button class="btn btn-info btn-round"  @click.prevent="handleAction('create')"><i class="now-ui-icons ui-1_simple-add"></i> 新增登入帳號</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData" empty-text="暫無資料">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="100" prop="name" label="帳號">
            </el-table-column>
            <el-table-column min-width="80" prop="fullName" label="使用人全名">
            </el-table-column>
            <el-table-column min-width="300" prop="permissionText" label="權限列表">
            </el-table-column>
            <el-table-column
              min-width="80"
              header-align="right"
              label="操作"
            >
              <template slot-scope="scope">
               <div class="text-right table-actions">
                <n-button size="sm" class="mx-2 btn btn-default btn-round" @click.native="handleAction('modify', scope.row)">
                  <i class="now-ui-icons ui-2_settings-90"></i>
                  修改
                </n-button>
              </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui'
import { Checkbox } from 'src/components'
import { getLocalStorage } from 'src/util/index.js'
import API from 'src/api/index.js'
import _ from 'lodash'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox
  },
  data() {
    return {
      tableData: [],
      paramKeyCols: [
        {
          key: 'member',
          label: '會員列表'
        },
        {
          key: 'product',
          label: '商品列表'
        },
        {
          key: 'productEdit',
          label: '商品修改'
        },
        {
          key: 'order',
          label: '當日訂單'
        },
        {
          key: 'closeOrder',
          label: '關帳紀錄'
        },
        {
          key: 'reportHistory',
          label: '歷史報表'
        },
        {
          key: 'reportDaily',
          label: '每日銷售明細'
        },
        {
          key: 'reportSales',
          label: '銷售員銷售分析'
        },
        {
          key: 'reportVoucher',
          label: '回收券明細'
        },
        {
          key: 'reportCoupon',
          label: '免掛券明細'
        },
        {
          key: 'staff',
          label: '銷售人員管理'
        },
        {
          key: 'auth',
          label: '登入帳號管理'
        }
      ],
      acl: {}
    }
  },
  methods: {
    async init () {
      let userResult = await API.getUser()
      // let authResult = await API.getAuthority()
      if (userResult.message === 'success') {
        this.tableData = _.map(userResult.data, r => {
          let paramKey = []
          _.forEach(r.authority, (o, index) => {
            if (o === true) {
              paramKey.push(index)
            }
          })
          r.permissionText = this.renderAuthDescription(paramKey)
          return r
        })
      }
    },
    handleAction (type, data) {
      switch (type) {
        case 'create':
          this.$router.push('/forms/account/add')
          break
        case 'modify':
          this.$router.push(`/forms/account/modify/${data.id}`)
          break
        case 'authority':
          this.$router.push('/forms/authority')
        break
          
      }
    },
    renderAuthDescription (paramKey) {
      let authText = ''
      if (paramKey.length === 0) {
        authText += '尚無任何權限設定'
      }
      _.forEach(paramKey, value => {
        switch (value) {
          case 'auth':
            return authText += ' 登入帳號管理 /'
          case 'closeOrder':
            return authText += ' 關帳紀錄 /'
          case 'member':
            return authText += ' 會員列表 /'
          case 'order':
            return authText += ' 當日訂單 /'
          case 'product':
            return authText += ' 商品列表 /'
          case 'productEdit':
            return authText += ' 商品修改 /'
          case 'reportVoucher':
            return authText += ' 回收券明細 /'
          case 'reportDaily':
            return authText += ' 每日銷售明細 /'
          case 'reportHistory':
            return authText += ' 歷史報表 /'
          case 'reportSales':
            return authText += ' 銷售員銷售分析 /'
          case 'reportCoupon':
            return authText += ' 免掛券明細 /'
          case 'staff':
            return authText += ' 銷售人員管理 /'
        }
      })
      return authText
    }
  },
  computed: {
  },
  mounted () {
    let localStorageData = getLocalStorage('chengyoData')
    if (_.get(localStorageData, 'acl', {})) {
      this.acl = localStorageData.acl
    }
    this.init()
  }
}
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-transparent {
  background-color: transparent !important;
}
</style>