<template>
  <div class="today-orders">
  <div class="card card-stats card-raised">
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_money-coins"></i>
              </div>
              <h3 class="info-title">
                <small>$</small>
                <animated-number :value="computedSummary.salePrice" :formatValue="formatter" :duration="500"></animated-number>
              </h3>
              <h6 class="stats-title">期間銷售總額</h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-default">
                <i class="now-ui-icons shopping_box"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="computedSummary.productQty" :formatValue="formatter" :duration="500"></animated-number><small> 個</small>
              </h3>
              <h6 class="stats-title">銷售總數</h6>
              <h6 class="stats-title">不重複商品銷售總數 <animated-number :value="computedSummary.productCount" :formatValue="formatter" :duration="500"></animated-number><small> 項</small></h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info">
                <i class="now-ui-icons users_single-02"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="computedSummary.member" :formatValue="formatter" :duration="500"></animated-number><small> 人</small>
              </h3>
              <h6 class="stats-title">回診會員總數，期間新增 ({{todayNewMember}}人）</h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-default">
                <i class="now-ui-icons ui-1_calendar-60"></i>
              </div>
              <h3 class="info-title">
                <div class="justify-content-end">
                  <el-date-picker
                    v-model="datePicker"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="開始日期"
                    end-placeholder="結束日期"
                    :picker-options="pickerOptions"
                    :default-time="['00:00:00', '23:59:59']"
                    value-format="timestamp"
                    @change="handleChange"
                  >
                  </el-date-picker>
                </div>
              </h3>
              <div class="d-flex justify-content-center">
                <div class="stats-title mr-2 mb-0">選擇查詢期間</div>
                <button class="border-0 text-primary pointer bg-white p-0 outline-none" @click.prevent="handleSearch">[進階篩選條件]</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 slot="header" class="card-title">每日銷售明細</h4>
          <div class="align-self-center">
            <div class="d-flex justify-content-end align-items-center">
              <button class="btn btn-info" @click.prevent="exportExcel" :disabled="computedExportDisabled"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出每日銷售明細</button>
            </div>
          </div>
        </div>
        <div class="col-12 align-self-right">
          <div class="d-flex justify-content-end align-items-center text-muted">
            <div class="text-primary mr-2">{{computedSearchText}}</div>
            <span class="mr-2">目前資料筆數 {{isSearchMode ? searchTableByDay.length + ' / ' + tableByDay.length : tableByDay.length}} 筆</span>
            <button v-if="isSearchMode" class="border-0 text-primary pointer bg-white p-0 outline-none" @click.prevent="isSearchMode = !isSearchMode">清空</button>
          </div>
        </div> 
        <!-- 歷史報表 -->
        <div class="col-12">
          <el-table stripe header-cell-class-name="font-14" :data="isSearchMode ? searchTableByDay : tableByDay">
            <el-table-column v-for="column in tableByDayCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
              <template slot-scope="scope">
                <div v-if="column.prop === 'salePriceTotal' || column.prop === 'salePrice'">
                  $ {{ formatter(scope.row[column.prop]) }}
                </div>
                <div v-else>{{ scope.row[column.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
  </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Select, Option, Button, DatePicker, Loading} from 'element-ui'
import { renderMemberType, renderStoreType, getLocalStorage, setLocalStorage } from 'src/util/index.js'
import { AnimatedNumber } from 'src/components'
import lang from 'element-ui/lib/locale/lang/zh-TW'
import locale from 'element-ui/lib/locale'
import moment from 'moment'
import XLSX from 'xlsx'
import Swal from 'sweetalert2'
import _ from 'lodash'

locale.use(lang)

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    AnimatedNumber
  },
  data() {
    return {
      tableByDayCols: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 150
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 120
        },
        {
          prop: 'memberType',
          label: '會員級別',
          minWidth: 100,
          formatter: (value) => {
            return renderMemberType(value)
          }
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 100
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 100
        },
        {
          prop: 'category',
          label: '群組分類',
          minWidth: 80
        },
        {
          prop: 'SKU',
          label: '商品編號',
          minWidth: 80
        },
        {
          prop: 'name',
          label: '商品名稱',
          minWidth: 180
        },
        {
          prop: 'qty',
          label: '數量',
          minWidth: 80
        },
        {
          prop: 'salePriceTotal',
          label: '商品總額',
          minWidth: 100
        },
        {
          prop: 'staffName',
          label: '銷售人員',
          minWidth: 80
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一週',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          }
        ]
      },
      filter: {
        SKU: null,
        category: [],
        staffName: [],
      },
      isSearchMode: false,
      todayNewMember: 0,
      tableData: [],
      tableByDay: [],
      searchTableByDay: [],
      staff: [],
      datePicker: []
    }
  },
  methods: {
    async init() {
      let localStorageData = getLocalStorage('chengyoData')
      if (_.values(_.get(localStorageData, 'datePicker', {})).length > 0) {
        this.datePicker.push(localStorageData.datePicker.startTime)
        this.datePicker.push(localStorageData.datePicker.endTime)
        this.handleChange()
      }
    },
    async handleChange () {
      if (this.datePicker === null) {
        this.tableByDay = []
        this.isSearchMode = false
        setLocalStorage('chengyoData', 'datePicker', {})
        return
      }
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let startTime = moment(this.datePicker[0]).format('X')
      let endTime = moment(this.datePicker[1]).format('X')
      let obj = {startTime, endTime}
      let result = await API.getReportDaily(JSON.stringify(obj))
      if (result.message === 'success') {
        this.todayNewMember = result.todayNewMember
        this.tableByDay = _.map(result.data, r => {
          // 報表因為是row data，需要參考折扣價格計算總價，無法列出單價，金額不是單純的數量*單價
          let count = r.qty / r.quantityDiscout
          r.mappingPrice = (count >= 1 && r.quantityDiscoutPrice) ? r.quantityDiscoutPrice : r.salePrice
          if (count >= 1) {
            r.salePriceTotal = Math.floor(count) * Number(r.mappingPrice) + (r.qty % r.quantityDiscout * Number(r.salePrice))
          } else {
            r.salePriceTotal = r.qty * Number(r.salePrice)
          }
          r.tradeDate = moment(r.tradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
          r.openTradeDate = moment(r.openTradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
          r.closeTradeDate = moment(r.closeTradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
          r.memberType = renderMemberType(r.memberType)
          r.branchID = renderStoreType(r.branchID)
          return r
        })
      }
      this.isSearchMode = false
      setLocalStorage('chengyoData', 'datePicker', {startTime: this.datePicker[0], endTime: this.datePicker[1]})
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    filterData (type) {
      return _.chain(this.tableByDay)
        .map(r => {
          r.category = !r.category ? '未分類' : r.category
          return r
        })
        .groupBy(type)
        .value()
    },
    handleSearch () {
      let category = this.filterData('category')
      let staffName = this.filterData('staffName')
      Swal.fire({
        title: '進階篩選條件',
        html: `<div>
                <div>
                  <label>產品編號</label>
                  <div class="form-group">
                    <input id="SKU" type="number" v-model="search.sku" min="1" class="form-control" placeholder="請輸入產品編號" />
                  </div>
                </div>
                <div>
                  <label>產品群組</label>
                  <div class="text-justify row m-2">
                    ${Object.keys(category).length >= 1 ? Object.keys(category).map(key => {
                      return `
                      <div class="form-check mr-3">
                        <label class="form-check-label">
                          <input name="category" class="form-check-input" type="checkbox" value="${key}" />
                          <span class="form-check-sign">${key}</span>
                        </label>
                      </div>`
                    }).join('') : 
                    `<div class="alert alert-primary alert-with-icon w-100 mb-0" data-notify="container">
                      <span data-notify="icon" class="now-ui-icons ui-1_bell-53"></span>
                      <span data-notify="message" class="">目前查詢期間尚無群組資料</span>
                    </div>`}
                  </div>
                </div>
                <div>
                  <label>銷售人員</label>
                  <div class="text-justify row m-2">
                    ${Object.keys(staffName).length >= 1 ? Object.keys(staffName).map(key => {
                      return `
                      <div class="form-check mr-3">
                        <label class="form-check-label">
                          <input name="staffName" class="form-check-input" type="checkbox" value="${key}" />
                          <span class="form-check-sign">${key}</span>
                        </label>
                      </div>`
                    }).join('') : 
                    `<div class="alert alert-primary alert-with-icon w-100 mb-0" data-notify="container">
                      <span data-notify="icon" class="now-ui-icons ui-1_bell-53"></span>
                      <span data-notify="message" class="">目前查詢期間尚無銷售人員資料</span>
                    </div>`}
                  </div>
                </div>
              </div>`,
        buttonsStyling: false,
        confirmButtonText: '過濾條件',
        confirmButtonClass: 'btn btn-outline-primary btn-lg btn-round btn-block',
        focusConfirm: false,
        preConfirm: () => {
          // 分兩段執行
          let checkedCategory = []
          let checkedStaffName = []
          let SKU = document.getElementById('SKU').value
          let categoryCheckboxes = document.getElementsByName('category')
          let staffNameCheckboxes = document.getElementsByName('staffName')
          for (let checkbox of categoryCheckboxes) {
            if (checkbox.checked) {
              checkedCategory.push(checkbox.value)
            }
          }
          for (let checkbox of staffNameCheckboxes) {
            if (checkbox.checked) {
              checkedStaffName.push(checkbox.value)
            }
          }
          this.filter.SKU = SKU ? SKU : null
          this.filter.category = checkedCategory
          this.filter.staffName = checkedStaffName
          if (SKU || checkedCategory || checkedStaffName) {
            let searchArr = []
            // 業務人員 && 群組
            if (checkedCategory.length >= 1 && checkedStaffName.length >= 1) {
              checkedCategory.forEach(key => {
                _.cloneDeep(this.tableByDay).filter(r => {
                  if (r.category === key) { 
                    searchArr.push(r) 
                  }
                })
              })
              let mappingSearchArr = _.cloneDeep(searchArr)
              searchArr = []
              checkedStaffName.forEach(key => {
                mappingSearchArr.forEach(obj => {
                  if (obj.staffName === key) {
                    searchArr.push(obj)
                  }
                })
              })
            } else if (checkedCategory.length >= 1) {
              // 群組
              checkedCategory.forEach(key => {
                _.cloneDeep(this.tableByDay).filter(r => {
                  if (r.category === key) { searchArr.push(r) }
                })
              })
            } else if (checkedStaffName.length >= 1) {
              // 銷售人員
              checkedStaffName.forEach(key => {
                _.cloneDeep(this.tableByDay).filter(r => {
                  if (r.staffName === key) { searchArr.push(r) }
                })
              })
            } else {
              searchArr = this.tableByDay
            }
            this.searchTableByDay = SKU ? searchArr.filter(r => r.SKU === SKU) : searchArr
            this.isSearchMode = true
          }
        }
      })
    },
    exportExcel () {
      let title = '每日銷售明細'
      let sheetList = ['每日銷售明細']
      let wb = XLSX.utils.book_new()
      let startTime = moment(this.datePicker[0], 'x').format('YYYY-MM-DD')
      let endTime = moment(this.datePicker[1], 'x').format('YYYY-MM-DD')
      _.forEach(sheetList, name => {
        if (name === '每日銷售明細') {
          let ws, resolveData
          let data = _.map(this.isSearchMode ? this.searchTableByDay : this.tableByDay, r => {
            let { tradeDate, tradeID, memberID, memberName, memberType, category, SKU, name, qty, salePriceTotal, staffName, openTradeDate, closeTradeDate, branchID } = r
            return { tradeDate, tradeID, memberID, memberName, memberType, category, SKU, name, qty, salePriceTotal, staffName,openTradeDate, closeTradeDate, branchID }
          })
          // tradeID, memberID, memberName, memberType, salePrice, openTradeDate, closeTradeDate, branchID
          let header = [{tradeDate: '交易日期', tradeID: '交易單號', memberID: '會員編號', memberName: '會員姓名', memberType: '會員級別', category: '商品群組', SKU: '商品編號', name: '商品名稱', qty: '數量', salePriceTotal: '總品金額', staffName: '銷售人員', openTradeDate: '開帳時間', closeTradeDate: '關帳時間', branchID: '營業地點名稱'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        }
      })
      XLSX.writeFile(wb, title + `(${startTime} - ${endTime})`  + '.xlsx')
    },
    formatter (value) {
      return `${value.toLocaleString()}`
    }
  },
  computed: {
    computedExportDisabled () {
      return !this.datePicker || this.datePicker.length === 0
    },
    computedSummary () {
      let salePrice = _.chain(this.isSearchMode ? this.searchTableByDay : this.tableByDay)
        .cloneDeep()
        .sumBy('salePriceTotal')
        .value()
      let productCount = _.chain(this.isSearchMode ? this.searchTableByDay : this.tableByDay)
        .cloneDeep()
        .groupBy('name')
        .keys()
        .value()
      let productQty = _.chain(this.isSearchMode ? this.searchTableByDay : this.tableByDay)
        .cloneDeep()
        .sumBy('qty')
        .value()
      let member = _.chain(this.isSearchMode ? this.searchTableByDay : this.tableByDay)
        .groupBy('memberID')
        .keys()
        .value()
      return {salePrice, member: member.length, productCount: productCount.length, productQty, total: this.isSearchMode ? this.searchTableByDay : this.tableByDay.length}
    },
    computedSearchText () {
      let reusltText = ''
      _.forEach(this.filter, (r, key) => {
        if (key === 'SKU' && r !== null) {
           reusltText += `商品編號(${r})`
        }
        if (key === 'category' && r.length >= 1) {
          reusltText += reusltText ? `、商品分類(${r})` : `商品分類(${r})`
        }
        if (key === 'staffName' && r.length >= 1) {
          reusltText += reusltText ? `、銷售人員(${r})` : `銷售人員(${r})`
        }
      })
      return this.isSearchMode ?  `目前顯示篩選條件符合：${reusltText}` : ''
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style></style>
