<template>
  <div class="row">
    <div class="col-6">
    <card card-body-classes="table-full-width">
       <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <h4 class="card-title">醫師名單管理</h4>
        <div class="align-self-center">
          <div class="d-flex justify-content-end">
            <button class="btn btn-info btn-round" @click.prevent="handleAction('create', {type: {shortCode: 'D', name: '醫師'}})"><i class="now-ui-icons ui-1_simple-add"></i> 新增醫師</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="mappingStaff('D')" empty-text="暫無資料">
            <el-table-column width="100" type="index"></el-table-column>
            <el-table-column min-width="80" prop="staffID" label="代號">
              <template slot-scope="props">
                <a href="#" @click.prevent="handleAction('modify', props.row)">{{props.row['staffID']}}</a>
              </template>
            </el-table-column>
            <el-table-column min-width="120" prop="name" label="醫師全名">
              <template slot-scope="props">
                <a href="#" @click.prevent="handleAction('modify', props.row)">{{props.row['name']}}</a>
              </template>
            </el-table-column>
            <el-table-column width="150" prop="phoneNumber" label="聯絡電話"></el-table-column>
            <el-table-column min-width="80" header-align="right" label="操作">
              <div class="text-right table-actions" slot-scope="props">
                <n-button @click.native="handleAction('del', props.row)" class="remove" type="outline-primary" size="sm" round icon>
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
    </div>
    <div class="col-6">
      <card card-body-classes="table-full-width">
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">銷售名單管理</h4>
          <div class="align-self-center">
            <div class="d-flex justify-content-end">
              <button class="btn btn-info btn-round" @click.prevent="handleAction('create', {type: {shortCode: 'S', name: '銷售人員'}})"><i class="now-ui-icons ui-1_simple-add"></i> 新增銷售人員</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="mappingStaff('S')" empty-text="暫無資料">
              <el-table-column width="100" type="index"></el-table-column>
              <el-table-column min-width="80" prop="staffID" label="代號">
                <template slot-scope="props">
                <a href="#" @click.prevent="handleAction('modify', props.row)">{{props.row['staffID']}}</a>
              </template>
              </el-table-column>
              <el-table-column min-width="120" prop="name" label="銷售人員全名">
                <template slot-scope="props">
                <a href="#" @click.prevent="handleAction('modify', props.row)">{{props.row['name']}}</a>
              </template>
              </el-table-column>
              <el-table-column min-width="150" prop="phoneNumber" label="聯絡電話"></el-table-column>
              <el-table-column min-width="80" header-align="right" label="操作">
                <div class="text-right table-actions" slot-scope="props">
                  <n-button @click.native="handleAction('del', props.row)" class="remove" type="outline-primary" size="sm" round icon>
                    <i class="now-ui-icons ui-1_simple-remove"></i>
                  </n-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Loading } from 'element-ui'
import moment from 'moment'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      staff: []
    }
  },
  methods: {
    async init () {
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let result = await API.getStaff()
      if (result.message === 'success') {
        this.staff = result.data
      }
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    mappingStaff(type) {
      return _.chain(this.staff)
        .cloneDeep()
        .filter(r => r.type.shortCode === type)
        .sortBy('staffID')
        .value()
    },
    handleAction(type, data) {
      let action = ''
      switch (type) {
        case 'create':
          action = '新增'
          data.name = ''
          data.staffID = ''
          data.phoneNumber = ''
          break
        case 'modify':
          action = '修改'
          break
        case 'del':
          action = '刪除'
          break
      }
      let staff = data.type.shortCode === 'D' ? '醫師' : '銷售人員'
      Swal.fire({
        title: `<span class="text-primary">${action}</span>${staff}資料`,
        html: `<div class="form-horizontal">
                <div>${action}<span class="text-primary">${data.name}</span>${staff}資料</div>
                <br/>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">${staff}編號</label>
                    <div class="col-md-8">
                      <input id="staffID" type="text" value="${data.staffID ? data.staffID : ''}" placeholder="請輸入編號" class="form-control"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">姓名</label>
                    <div class="col-md-8">
                      <input id="name" type="text" value="${data.name ? data.name : ''}" placeholder="請輸入姓名" class="form-control"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">聯絡電話</label>
                    <div class="col-md-8">
                      <input id="phoneNumber" type="number" value="${data.phoneNumber ? data.phoneNumber : ''}" placeholder="請輸入電話" class="form-control"/>
                    </div>
                  </div>
                </div>
              </div>`,
        buttonsStyling: false,
        confirmButtonText: `<i class="fa fa-save"></i> 確認${action}`,
        confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
        preConfirm: async () => {
          let mappingData = {}
          let name = document.getElementById('name').value
          let staffID = document.getElementById('staffID').value
          let phoneNumber = document.getElementById('phoneNumber').value
          mappingData = {
            name,
            staffID,
            phoneNumber,
            workingDate: moment().format('X'),
            email: '',
            type: {shortCode: data.type.shortCode, name: staff}
          }
          let apiType = null
          if (type === 'create') {
            apiType = await API.createStaff(JSON.stringify(mappingData))
          } else if (type === 'modify'){
            apiType = await API.modifyStaff(JSON.stringify(mappingData), data.id)
          } else {
            apiType = await API.deleteStaff(data.id)
          }
          let result = apiType
          if (result.message === 'success') {
            Swal.fire({
              title: `${action} ${name} 資料成功`,
              type: 'success'
            })
            await this.init()
            await this.mappingStaff(data.type.shortCode)
          } else {
            Swal.fire({
              title: `${action} ${name} 資料失敗，請洽系統管理員`,
              icon: 'error'
            })
          }
        }
      })
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss">
.table-transparent {
  background-color: transparent !important;
}
</style>