<template>
  <div class="row">
    <div class="col-6">
    <card card-body-classes="table-full-width">
       <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <h4 class="card-title">群組管理</h4>
        <div class="align-self-center">
          <div class="d-flex justify-content-end">
            <button class="btn btn-info btn-round" @click.prevent="handleAction('create', {name: ''})"><i class="now-ui-icons ui-1_simple-add"></i> 新增群組</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="category" empty-text="暫無資料">
            <el-table-column width="100" type="index"></el-table-column>
            <el-table-column min-width="80" prop="name" label="群組名稱">
              <template slot-scope="props">
                <a href="#" @click.prevent="handleAction('modify', props.row)">{{props.row['name']}}</a>
              </template>
            </el-table-column>
            <el-table-column min-width="80" header-align="right" label="操作">
              <div class="text-right table-actions" slot-scope="props">
                <n-button @click.native="handleAction('del', props.row)" class="remove" type="outline-primary" size="sm" round icon>
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
    </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Loading } from 'element-ui'
import Swal from 'sweetalert2'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      category: [],
      product: []
    }
  },
  methods: {
    async init () {
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let productResult = await API.getProduct()
      let categoryResult = await API.getCategory()
      if (categoryResult.message === 'success') {
        this.category = categoryResult.data
      }
      if (productResult.message === 'success') {
        this.product = productResult.data
      }
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    handleAction(type, data) {
      console.log(data)
      let action = ''
      let productFilter = []
      switch (type) {
        case 'create':
          action = '新增'
          break
        case 'modify':
          productFilter = this.product.filter(r => r.category === data.name)
          action = '修改'
          break
        case 'del':
          productFilter = this.product.filter(r => r.category === data.name)
          action = '刪除'
          break
      }
      Swal.fire({
        title: `<span class="text-primary">${action}</span>資料`,
        html: `<div class="form-horizontal">
                <div>${action}<span class="text-primary">${data.name ? data.name : '群組'}</span>資料</div>
                <br/>
                <div class="form-group">
                  <div class="row">
                    <label class="col-md-4 col-form-label">商品群組</label>
                    <div class="col-md-8">
                      <input id="name" type="text" value="${data.name}" placeholder="請輸入商品群組" class="form-control"/>
                    </div>
                    ${(type === 'modify' || type === 'del') ? `<div class="col-12 text-center mt-3">目前套用「${data.name}」群組的商品共有<span class="text-primary font-weight-bolder"> ${productFilter.length} </span>項</div><br/><div class="col-12 text-center mt-3">確認修改將會進行產品更新</div>` : ''}
                  </div>
                </div>
              </div>`,
        buttonsStyling: false,
        confirmButtonText: `<i class="fa fa-save"></i> 確認${action}`,
        confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
        preConfirm: async () => {
          let mappingData = {}
          let name = document.getElementById('name').value
          mappingData = {
            name
          }
          let apiType = null
          if (type === 'create') {
            apiType = await API.createCategory(JSON.stringify(mappingData))
          } else if (type === 'modify' || type === 'del'){
            let mappingBatch = this.product.reduce((r, o) => {
              if (o.category === data.name) {
                (type === 'modify') ? r.push({'productUUID': o.productUUID, 'category': name}) : r.push({'productUUID': o.productUUID, 'category': ''})
              }
              return r
            }, [])
            // first 修改/刪除 群組名稱
            // sencond 批次套用產品修改/或清除群組名稱
            let result = (type === 'modify') ? await API.modifyCategory(data.id, JSON.stringify({name})) : await API.deleteCategory(data.id)
            if (result.message === 'success') {
              apiType = await API.productCategoryBatch(JSON.stringify(mappingBatch))
            }
          }
          let result = apiType
          if (result.message === 'success') {
            Swal.fire({
              title: `${action} ${name} 資料成功`,
              type: 'success'
            })
            await this.init()
          } else {
            Swal.fire({
              title: `${action} ${name} 資料失敗，請洽系統管理員`,
              icon: 'error'
            })
          }
        }
      })
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss">
.table-transparent {
  background-color: transparent !important;
}
</style>