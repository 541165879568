<template>
<div class="today-orders">
    <div class="card card-stats card-raised">
      <div class="card-body">
        <div class="row">
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-primary">
                <i class="now-ui-icons business_money-coins"></i>
              </div>
              <h3 class="info-title">
                <small> $ </small>
                <animated-number :value="renderSummary.totalSaleAmount" :formatValue="formatter" :duration="500"></animated-number>
              </h3>
              <h6 class="stats-title">今日營業總額（共 {{renderSummary.total}} 筆紀錄）</h6>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-default">
                <i class="now-ui-icons users_single-02"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="renderSummary.member" :formatValue="formatter" :duration="500"></animated-number><small> 人</small>
              </h3>
              <h6 class="stats-title">今日回診會員數，新增 ({{todayInfo.newMember}}人）</h6>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-info">
                <i class="now-ui-icons education_paper"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="todayInfo.gift" :formatValue="formatter" :duration="500"></animated-number><small> 張</small>
              </h3>
              <h6 class="stats-title">今日回收券數量</h6>
            </div>
          </div>
        </div>
        
        <div class="col-2">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-success">
                <i class="now-ui-icons education_paper"></i>
              </div>
              <h3 class="info-title">
                <animated-number :value="todayInfo.coupon" :formatValue="formatter" :duration="500"></animated-number><small> 張</small>
              </h3>
              <h6 class="stats-title">今日免掛券數量</h6>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="statistics">
            <div class="info">
              <div class="icon icon-default">
                <i class="now-ui-icons loader_gear"></i>
              </div>
              <h3 class="info-title">
                <div class="">
                  <button type="button" el="tooltip" class="btn btn-info btn-round" @click.prevent="handleAction('open')" v-if="!isCloseOrder">
                    <i class="now-ui-icons media-1_button-power"></i> 執行開帳
                  </button>
                  <button type="button" el="tooltip" class="btn btn-primary btn-round" @click.prevent="handleAction('showClose')" v-if="!isCloseOrderFlag && isCloseOrder">
                    <i class="now-ui-icons media-1_button-power"></i> 執行關帳
                  </button>
                </div>
                <div class="" v-if="isCloseOrderFlag">
                  <n-button @click.native="goBackOrder" class="btn btn-round" >
                    <i slot="label" class="now-ui-icons arrows-1_minimal-left"></i>
                    取消
                  </n-button>
                  <button class="btn btn-info btn-round" @click.prevent="uploadFile" :disabled="tableByMember.length === 0">
                    <i class="now-ui-icons arrows-1_cloud-upload-94"></i>
                    上傳比對資料</button>
                  <input ref="uploadFile" type="file" class="d-none" @change="handlePreview($event)" id="uploadFile" accept=".xlsx, .xls">
                </div>
              </h3>
              <h6 class="stats-title"> </h6>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width p-0" no-footer-line>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-4 pr-0">
          <div class="d-flex justify-content-start">
            <h4 slot="header" class="card-title m-0">{{renderView.text}}</h4>
            <div class="ml-2 align-self-center" v-if="!isCloseOrderFlag">
              <button type="button" class="btn border-0 bg-white text-primary px-2 py-0" @click.prevent="handleViewMode('order')">當日訂單</button> /
              <button type="button" class="btn border-0 bg-white text-primary px-2 py-0" @click.prevent="handleViewMode('sales')">銷售明細列表</button> /
              <button type="button" class="btn border-0 bg-white text-primary px-2 py-0" @click.prevent="handleViewMode('member')">會員銷售總額列表</button>
            </div>
          </div>
          <div class="d-flex justify-content-end" v-if="!isCloseOrderFlag">
            <fg-input class="text-right mb-0">
              <el-input type="search" clearable prefix-icon="el-icon-search" style="width: 250px" placeholder="搜尋會員名稱或編號" v-model="searchQuery" aria-controls="datatables"></el-input>
            </fg-input>
            <button class="btn btn-info my-0 mx-2" v-if="queriedData.length >= 1" @click.prevent="exportExcel(renderView.value)"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出{{renderView.text}}</button>
          </div>
        </div>
        <div>
          <!-- 未結帳訂單 -->
          <div v-if="isCloseOrder && !isCloseOrderFlag">
            <el-table stripe :data="queriedData" header-cell-class-name="font-14">
              <el-table-column v-for="col in mappingCols" :key="col.label" :min-width="col.minWidth" :width="col.width" :prop="col.prop" :label="col.label" :fixed="col.fixed" sortable>
                <template slot-scope="scope">
                  <div v-if="col.prop === 'tradeID'">
                    <div v-if="scope.row.returnOrderID">
                      <el-button v-if="renderView.value === 'order'" type="text" class="outline-none font-16" style="color: #f96332;" @click.prevent="showOrderDetails(scope.row, null, 'order')">{{ scope.row[col.prop] }}</el-button>
                      <div class="text-primary" v-else> {{ scope.row[col.prop] }} </div>
                      <span
                        class="el-tag el-tag--danger el-tag--small el-tag--light"
                        >退貨單</span
                      >
                    </div>
                    <el-button v-else type="text" class="outline-none font-16" style="color: #f96332;" @click.prevent="showOrderDetails(scope.row, null, 'order')">{{ scope.row[col.prop] }}</el-button>
                  </div>
                  <div v-else-if="col.prop === 'totalSaleAmount'">
                    $ {{ formatter(scope.row[col.prop]) }}
                  </div>
                  <div v-else-if="col.prop === 'salePrice'">
                    $ {{ formatter(scope.row[col.prop]) }}
                  </div>
                  <div v-else>{{ scope.row[col.prop] }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="my-2" v-if="isCloseOrderFlag">
            <div class="row">
            <div class="col-6 text-center me-1">
              <h5 class="card-title border-top border-bottom">系統資料</h5>
              <el-table stripe style="width: 100%" :data="tableByMember">
                <el-table-column type="expand">
                  <template slot-scope="scope" style="padding-right: 0px;">
                    <div v-for="item in scope.row.orders" :key="item.orderID" class="d-flex">
                      <div class="expand" style="flex: 1;">{{ item.date }}</div>
                      <div v-if="item.returnOrderID" class="expand" style="flex: 1;">
                        <el-button type="text" class="outline-none p-0 font-16" style="color: #f96332;" @click.prevent="showOrderDetails(scope.row, item, 'member')">{{ item.tradeID }}</el-button>
                        <span class="el-tag el-tag--danger el-tag--small el-tag--light">退貨單</span>
                      </div>
                      <div v-else class="expand" style="flex: 1;">
                        <el-button type="text" class="outline-none p-0" style="color: #f96332;" @click.prevent="showOrderDetails(scope.row, item, 'member')">{{ item.tradeID }}</el-button>
                      </div>
                      <div class="expand" style="flex: 1;">
                        {{ item.memberName }}
                      </div>
                      <div class="expand" style="flex: 1;">
                        $ {{ item.totalSaleAmount.toLocaleString() }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column v-for="column in tableByMemberCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label">
                  <template slot-scope="scope">
                    <span v-if="column.formatter">$ {{formatter(scope.row[column.prop])}}</span>
                    <span v-else>{{scope.row[column.prop]}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-6 text-center ms-1">
              <h5 class="card-title border-top border-bottom">上傳比對資料</h5>
              <el-table stripe style="width: 100%" :data="importTable">
                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label">
                    <template slot-scope="scope">
                    <span v-if="column.formatter">$ {{formatter(scope.row[column.prop])}}</span>
                    <span v-else>{{scope.row[column.prop]}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end col-12">
          <button type="button" el="tooltip" class="btn btn-success btn-round" @click.prevent="handleAction(isCloseType)" v-if="isCanClose">
            <i class="now-ui-icons ui-1_simple-add"></i> {{isCloseType === 'normal' ? '確認關帳' : '確認強制關帳'}}
          </button>
        </div>
      </card>
    </div>
  </div>
  <!-- 當日回收與免掛券 -->
  <div class="row" v-if="isCloseOrder && !isCloseOrderFlag">
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <div class="align-self-center">
          <div class="d-flex justify-content-end">
            <button class="btn btn-info my-0 mx-2" @click.prevent="exportExcel('gift')" :disabled="renderExportDisabled('gift')"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出當日回收券明細</button>
          </div>
        </div>
        <h6 class="mx-3 text-muted">當日回收券 x {{todayInfo.gift}} 張</h6>
        <el-table stripe :data="tableByVoucher.gift" header-cell-class-name="font-14" class="fixedH400">
          <el-table-column v-for="column in tableByDayVoucherCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
          </el-table-column>
        </el-table>
      </card>
    </div>
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <div class="align-self-center">
          <div class="d-flex justify-content-end">
            <button class="btn btn-success my-0 mx-2" @click.prevent="exportExcel('coupon')" :disabled="renderExportDisabled('coupon')"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出當日免掛券明細</button>
          </div>
        </div>
        <h6 class="mx-3 text-muted">當日免掛券 x {{todayInfo.coupon}} 張</h6>
        <el-table stripe :data="tableByVoucher.coupon" header-cell-class-name="font-14" class="fixedH400">
          <el-table-column v-for="column in tableByDayVoucherCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Upload, Button, Form, Loading } from 'element-ui'
import { AnimatedNumber } from 'src/components'
import { getLocalStorage, renderPaymentType, renderStoreType } from 'src/util/index.js'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import XLSX from 'xlsx'
import _ from 'lodash'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload,
    [Button.name]: Button,
    [Form.name]: Form,
    AnimatedNumber
  },
  data() {
    return {
      mappingCols: [],
      tableByDayCols: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 80
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 100
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 80
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 80
        },
        {
          prop: 'totalSaleAmount',
          label: '金額',
          minWidth: 80
        },
        {
          prop: 'mappingPayment',
          label: '付款方式',
          minWidth: 80
        },
        {
          prop: 'branchID',
          label: '分店編號',
          minWidth: 80
        }
      ],
      tableBySalesCols: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 100
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 120
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 80
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 80
        },
        {
          prop: 'category',
          label: '群組分類',
          minWidth: 80
        },
        {
          prop: 'SKU',
          label: '商品編號',
          minWidth: 80
        },
        {
          prop: 'name',
          label: '商品名稱',
          minWidth: 180
        },
        {
          prop: 'qty',
          label: '數量',
          minWidth: 80
        },
        {
          prop: 'salePrice',
          label: '商品價格',
          minWidth: 80
        },
        {
          prop: 'totalSaleAmount',
          label: '商品小計',
          minWidth: 80
        },
        {
          prop: 'staffName',
          label: '銷售人員',
          minWidth: 80
        }
      ],
      tableGroupMemberCols: [
        {
          prop: 'mappingTradeDate',
          label: '交易日期',
          width: 220,
          minWidth: 100
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 80,
          width: 120
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 80,
          width: 120
        },
        {
          prop: 'total',
          label: '總交易筆數',
          minWidth: 80,
          fixed: 'right'
        },
        {
          prop: 'totalSaleAmount',
          label: '金額',
          minWidth: 80,
          fixed: 'right'
        }
      ],
      tableByDayVoucherCols: [
        {
          prop: 'effectiveDate',
          label: '票券回收日期',
          minWidth: 80
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 80
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 80
        },{
          prop: 'voucherQty',
          label: '數量',
          minWidth: 80
        },
        {
          prop: 'voucherNumberStart',
          label: '票券號碼(起)',
          minWidth: 120
        },
        {
          prop: 'voucherNumberEnd',
          label: '票券號碼（迄）',
          minWidth: 120
        },
      ],
      tableByMemberCols: [
        {
          prop: 'date',
          label: '交易日期',
          minWidth: 80
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 80
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 80
        },
        {
          prop: 'totalMemberAmount',
          label: '金額',
          minWidth: 80,
          formatter: true
        }
      ],
      tableColumns: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 80
        },
        {
          prop: 'memberID',
          label: '掛號證',
          minWidth: 80
        },
        {
          prop: 'name',
          label: '會員姓名',
          minWidth: 80
        },
        {
          prop: 'totalAmount',
          label: '自費',
          minWidth: 80,
          formatter: true
        },
        {
          prop: 'mappingIsCorrect',
          label: '比對結果',
          minWidth: 80
        }
      ],
      searchQuery: '',
      renderView: {text: '當日訂單', value: 'order'},
      searchedData: [],
      fuseSearch: null,
      originTableByDay: [],
      tableByDay: [],
      tableByMember: [],
      tableByVoucher: [],
      importTable: [],
      staff: [],
      todayInfo: {newMember: 0, coupon: 0, gift: 0},
      isCloseOrder: false,
      isCloseOrderFlag: false,
      isCanClose: false,
      isCloseType: 'normal'
    }
  },
  watch: {
    searchQuery(value) {
      if (value !== '') {
        this.searchedData = this.fuseSearch.search(this.searchQuery).length === 0 ? [] : this.fuseSearch.search(this.searchQuery)
      } else {
        this.searchedData = []
      }
    }
  },
  methods: {
    async init() {
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let closeResult = await API.getUnclosedOrder()
      if (closeResult.message === 'success') {
        this.todayInfo = {
          newMember: closeResult.todayNewMember,
          coupon: closeResult.totalVoucherCoupon,
          gift: closeResult.totalVoucherGift
        }
        this.tableByDay = _.chain(closeResult.data)
          .cloneDeep()
          .map(r => {
            r.tradeDate = moment(r.tradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
            r.mappingPayment = renderPaymentType(r.payment)
            r.branchID = renderStoreType(r.branchID)
            return r
          })
          .reverse()
          .value()
        this.mappingCols = this.tableByDayCols
        this.originTableByDay = _.cloneDeep(this.tableByDay)
        if (Object.values(closeResult.voucherEffectiveList).length > 0) {
          _.map(closeResult.voucherEffectiveList, (arr, index) => {
            arr.reduce((r, o) => {
              o.effectiveDate = moment(o.effectiveDate, 'X').format('YYYY/MM/DD hh:mm:ss')
              r.push(o)
              return r
            }, [])
            this.tableByVoucher = Object.assign({[index]: arr}, this.tableByVoucher)
          })
        }
      }
      let recordResult = await API.getReconcile()
      if (recordResult.message === 'success') {
        this.tableByMember = _.chain(recordResult.data)
          .cloneDeep()
          .map(r => {
            r.date = moment(r.tradeDate, 'X').format('YYYY/MM/DD')
            _.map(r.orders, o => {
              o.memberID = r.memberID
              o.date = moment(o.tradeDate, 'X').format('YYYY/MM/DD')
              return o
            })
            return r
          })
          .sortBy('tradeDate')
          .value()
      }
      let reuslt = await API.checkCloseAccount()
      // data有資料：已開帳，無資料：未開帳
      if (reuslt.message === 'success') {
        this.isCloseOrder = Object.keys(reuslt.data).length >= 1
      }
      let staffResult = await API.getStaff()
      if (staffResult.message === 'success') {
        this.staff = staffResult.data
      }
      this.$nextTick(() => {
        loadingInstance.close()
      })
      this.searchFuse()
    },
    goBackOrder () {
      this.isCloseOrder = true
      this.isCloseOrderFlag = false
    },
    handleAction (type) {
      switch (type) {
        case 'open':
          Swal.fire({
            title: '請選擇開帳人員',
            html: `<div class="form-group">
                    <div>
                      <span>開帳人員：</span>
                      <input id="staff-recordID" type="number" min="0" class="swal2-input input-text" placeholder="請輸入關帳人員代碼"/>
                      <div>Tips: 使用Tab鍵 ➞ ctrl鍵 + Enter 可以快速輸入資料</div>
                    </div>`,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-info btn-fill',
            focusConfirm: false,
            preConfirm: async () => {
              let result = await this.handleCloseType('open')
              if (result) {
                // 已開帳
                Swal.fire({
                  title: '開帳成功',
                  type: 'success'
                })
                this.isCloseOrder = true
              }
            }
          })
          break
        case 'normal':
        case 'enforced':
          Swal.fire({
            title: type === 'enforced' ? '請選擇關帳人員（強制關帳）' : '請選擇關帳人員',
            html: `<div class="form-group">
                    <div>
                      <span>關帳人員：</span>
                      <input id="staff-recordID" type="number" min="0" class="swal2-input input-text" placeholder="請輸入關帳人員代碼"/>
                      <div>Tips: 使用Tab鍵 ➞ ctrl鍵 + Enter 可以快速輸入資料</div>
                    </div>`,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-info btn-fill',
            focusConfirm: false,
            preConfirm: async () => {
              let result = await this.handleCloseType('close', type)
              if (result) {
                // 已關帳
                this.isCloseOrderFlag = false
                this.isCanClose = false
                this.init()
              }
            }
          })
          break
        case 'showClose':
          this.isCloseOrderFlag = true
          break
      }
    },
    async handleCloseType (type, status) {
      let data = {}
      let staffID = document.getElementById('staff-recordID').value
      let staffInfo = _.find(this.staff, {staffID: staffID})
      if (staffInfo) {
        data = {
          staffID: document.getElementById('staff-recordID').value,
          name: staffInfo.name,
          status
        }
        let result = (type === 'open') ? await API.openCloseOrder(JSON.stringify(data)) : await API.closeCloseOrder(JSON.stringify(data))
        if (result.message === 'success') {
          return result
        } else {
          Swal.fire({
            title: '查詢失敗，請恰管理員。',
            type: 'warning'
          })
        }
      } else {
        Swal.fire({
          title: '查無此員工編號，請再試試。',
          type: 'warning'
        })
      }
    },
    uploadFile () {
      let element = document.getElementById('uploadFile')
      element.click()
    },
    handlePreview (event) {
      let file = event.target.files[0]
      if (!file) return
      this.renderFile(file)
      this.$refs.uploadFile.value = null
    },
    renderFile (file) {
       if (~file.name.indexOf('xlsx') || ~file.name.indexOf('xls')) {
        let reader = new FileReader()
        reader.onload = (e) => {
          let data = e.target.result
          let workbook = XLSX.read(data, {
            type: 'binary'
          })
          let rowObject = []
          _.forEach(workbook.SheetNames, (sheetName, sheetIndex) => {
            if (sheetIndex === 0) {
              let originObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], {blankRows: false, defval: null})
              // __EMPTY_20 備註
              // __EMPTY_12 自費金額 > 0 才進行比對，且忽略總計欄
              let data = _.filter(originObject, r => {
                if (r.__EMPTY_12 > 0 && r.__EMPTY_1) {
                  return r
                }
              })
              if (data.length >= 1) {
                _.chain(data)
                  .cloneDeep()
                  .map(o => {
                    let key = Object.keys(o)
                    let tradeDate = o.__EMPTY
                    let memberID = o.__EMPTY_1
                    let mappingMemberID = o.__EMPTY_1.replace('00', 'A3')
                    let name = o.__EMPTY_2
                    let totalAmount = o.__EMPTY_12
                    let formatterTradeDate = tradeDate.split('/')
                    o.tradeDate = tradeDate
                    o.mappingTradeDate = `${Number(formatterTradeDate[0]) + 1911}/${formatterTradeDate[1]}/${formatterTradeDate[2]}`
                    o.memberID = memberID
                    o.mappingMemberID = mappingMemberID
                    o.name = name
                    o.totalAmount = totalAmount
                    o.isCorrect = false
                    o.mappingIsCorrect = 'Ｘ'
                    _.forEach(key, index => {
                      delete o[index]
                    })
                    rowObject.push(o)
                    return o
                  })
                  .value()
                let obj = []
                _.map(this.tableByMember, arr => {
                  _.map(arr.orders, r => {
                      _.map(rowObject, o => {
                        if (r.memberID === o.mappingMemberID && r.totalSaleAmount === o.totalAmount && r.date === o.mappingTradeDate) {
                          o.isCorrect = true
                          o.mappingIsCorrect = 'Ｏ'
                          obj.push(o)
                        } else {
                          obj.push(o)
                        }
                    })
                  })
                })
                let mappingRowObject = _.uniq(obj)
                let index =  _.findIndex(mappingRowObject, {isCorrect: false})
                if (index > -1) {
                  Swal.fire({
                    title: '比對失敗',
                    text: '請再檢查是否有錯誤資料，比對資料包含交易日期、會員編號、自費金額',
                    icon: 'error'
                  })
                  this.isCloseType = 'enforced'
                } else {
                  Swal.fire({
                    title: '比對成功',
                    text: '請至下方確認執行關帳作業',
                    type: 'success'
                  })
                  this.isCloseType = 'normal'
                }
                this.isCanClose = true
                this.importTable = _.sortBy(mappingRowObject, 'tradeDate')
              } else {
                Swal.fire({
                  title: '比對失敗',
                  text: '請確認匯入檔案是否正確',
                  icon: 'error'
                })
                this.isCanClose = false
              }
            }
          })
        }
        reader.onerror = (e) => {
          console.log('error' + e.target.error.code)
        }
        reader.readAsBinaryString(file)
      }
    },
    renderExportDisabled (type) {
      if (this.tableByVoucher.length === 0) return
      return this.tableByVoucher[type].length === 0
    },
    handleViewMode (type) {
      this.searchQuery = ''
      if (type === 'sales') {
        this.renderView = {text: '銷售明細列表', value: type}
        this.mappingCols = this.tableBySalesCols
        this.tableByDay = this.originTableByDay.reduce((r, o) => {
          let {saleDetails = [], memberID, memberName, tradeDate, tradeID, returnOrderID} = o
          saleDetails.map(arr => {
            let {qty, salePrice} = arr
            arr.memberID = memberID
            arr.memberName = memberName
            arr.tradeDate = tradeDate
            arr.tradeID = tradeID
            arr.returnOrderID = returnOrderID
            arr.totalSaleAmount = qty * salePrice
            r.push(arr)
          })
          return r
        }, [])
      } else if (type === 'member') {
        this.renderView = {text: '會員銷售總額列表', value: type}
        this.mappingCols = this.tableGroupMemberCols
        this.tableByDay = _.chain(this.originTableByDay)
          .groupBy('memberID')
          .reduce((r, o) => {
            let mappingTradeDate = Object.keys(_.groupBy(o, 'tradeDate')).length > 1 ? _.last(Object.keys(_.groupBy(o, 'tradeDate'))) : Object.keys(_.groupBy(o, 'tradeDate')).join('')
            let memberID = Object.keys(_.groupBy(o, 'memberID')).join('')
            let memberName = Object.keys(_.groupBy(o, 'memberName')).join('')
            let total = _.size(Object.values(_.groupBy(o, 'memberID'))[0])
            let totalSaleAmount = _.sumBy(o, 'totalSaleAmount')
            r.push({mappingTradeDate, memberID, memberName, total, totalSaleAmount})
            return r
          }, [])
          .value()
      } else {
        this.renderView = {text: '當日訂單', value: type}
        this.mappingCols = this.tableByDayCols
        this.tableByDay = this.originTableByDay
      }
      this.searchFuse()
    },
    searchFuse () {
      this.fuseSearch = new Fuse(this.tableByDay, {
        keys: ['memberName', 'memberID'],
        threshold: 0.1,
        findAllMatches: true
      })
    },
    exportExcel (type) {
      let title = ''
      let sheetList = ''
      switch (type) {
        case 'gift':
          title = '回收券明細報表'
          sheetList = ['回收券明細報表'] 
          break
        case 'coupon':
          title = '免掛券明細報表'
          sheetList = ['免掛券明細報表']
          break
        case 'order': {
          Swal.fire({
            title: '此為未關帳前數據，建議僅作為試算參考，正確資料請以關帳後「每日銷售明細」數據為主。',
            type: 'info',
            buttonsStyling: false,
            confirmButtonText: '<i class="now-ui-icons ui-1_check"></i> 確認',
            confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
          })
          title = '當日訂單報表'
          sheetList = ['當日訂單報表']
          break
        }
        case 'sales': {
          Swal.fire({
            title: '此為未關帳前數據，建議僅作為試算參考，正確資料請以關帳後「每日銷售明細」數據為主。',
            type: 'info',
            buttonsStyling: false,
            confirmButtonText: '<i class="now-ui-icons ui-1_check"></i> 確認',
            confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
          })
          title = '銷售明細列表'
          sheetList = ['銷售明細列表']
          break
        }
        case 'member': {
          Swal.fire({
            title: '此為未關帳前數據，建議僅作為試算參考，正確資料請以關帳後「每日銷售明細」數據為主。',
            type: 'info',
            buttonsStyling: false,
            confirmButtonText: '<i class="now-ui-icons ui-1_check"></i> 確認',
            confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
          })
          title = '會員銷售總額列表'
          sheetList = ['會員銷售總額列表']
          break
        }

      }
      let wb = XLSX.utils.book_new()
      _.forEach(sheetList, name => {
        if (name === '回收券明細報表') {
          let ws, resolveData
          let data = _.map(this.tableByVoucher[type], r => {
            let { effectiveDate, memberID, memberName, voucherQty, voucherNumberStart, voucherNumberEnd, tradeID } = r
            return { effectiveDate, memberID, memberName, voucherQty, voucherNumberStart, voucherNumberEnd, tradeID}
          })
          let header = [{effectiveDate: '票券回收日期', tradeID: '交易單號',memberID: '會員編號', memberName: '會員姓名', voucherQty: '數量', voucherNumberStart: '票券號碼(起)', voucherNumberEnd: '票券號碼（迄）'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        } else if (name === '免掛券明細報表') {
          let ws, resolveData
          let data = _.map(this.tableByVoucher[type], r => {
            let { effectiveDate, memberID, memberName, voucherQty, voucherNumberStart, voucherNumberEnd, tradeID } = r
            return { effectiveDate, memberID, memberName, voucherQty, voucherNumberStart, voucherNumberEnd, tradeID}
          })
          let header = [{effectiveDate: '票券回收日期', tradeID: '交易單號',memberID: '會員編號', memberName: '會員姓名', voucherQty: '數量', voucherNumberStart: '票券號碼(起)', voucherNumberEnd: '票券號碼（迄）'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        } else if (name === '當日訂單報表') {
          let ws, resolveData
          let data = _.map(this.queriedData, r => {
            let { tradeDate, tradeID, memberID, memberName, totalSaleAmount, mappingPayment, branchID } = r
            return { tradeDate, tradeID, memberID, memberName, totalSaleAmount, mappingPayment, branchID}
          })
          let header = [{tradeDate: '交易日期', tradeID: '交易單號',memberID: '會員編號', memberName: '會員姓名', totalSaleAmount: '金額', mappingPayment: '付款方式', branchID: '分店編號'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        } else if (name === '銷售明細列表') {
          let ws, resolveData
          let data = _.map(this.queriedData, r => {
            let { tradeDate, tradeID, memberID, memberName, totalSaleAmount } = r
            return { tradeDate, tradeID, memberID, memberName, totalSaleAmount}
          })
          let header = [{tradeDate: '交易日期', tradeID: '交易單號',memberID: '會員編號', memberName: '會員姓名', totalSaleAmount: '金額'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        } else if (name === '會員銷售總額列表') {
          let ws, resolveData
          let data = _.map(this.queriedData, r => {
            let { memberID, memberName, totalSaleAmount } = r
            return { memberID, memberName, totalSaleAmount}
          })
          let header = [{memberID: '會員編號', memberName: '會員姓名', totalSaleAmount: '金額'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        }
      })
      XLSX.writeFile(wb, title + '.xlsx')
    },
    async searchMember (data) {
      let obj = {searchKey: data}
      let result = await API.searchMember(JSON.stringify(obj))
      if (result.message === 'success') {
        let consultation = await API.getMemberConsultation(result.data[0].id)
        result.data[0].consultation = _.chain(consultation.data)
          .reverse()
          .map(r => {
            r.lastDate = moment(r.date, 'X').format('YYYY-MM-DD')
            return r
          })
          .head()
          .value()
        return result
      }
    },
    async showOrderDetails (data, item, type) {
      let localStorageData = getLocalStorage('chengyoData')
      let result = await this.searchMember(data.memberID)
      if (localStorageData && result) {
        if (type === 'order') {
          _.map(data.saleDetails, r => {
            r.originQty = r.qty
            r.originSalePrice = r.salePrice
            r.originRewardPrice = _.get(r, 'rewardPrice', 0)
            // 報表因為是row data，需要參考折扣價格計算總價，無法列出單價，金額不是單純的數量*單價
            let count = r.qty / r.quantityDiscout
            r.mappingPrice = (count >= 1 && r.quantityDiscoutPrice) ? r.quantityDiscoutPrice : r.salePrice
            if (count >= 1) {
              r.totalAmount = Math.floor(count) * Number(r.mappingPrice) + (r.qty % r.quantityDiscout * Number(r.salePrice))
            } else {
              r.totalAmount = r.qty * Number(r.salePrice)
            }
            r.salesName = `(${r.staffID}) ${r.staffName}`
            return r
          })
          data.voucherEffectiveList = _.map(data.voucherEffectiveList, r => {
            return Object.assign({}, {voucherNumber: r.voucherNumber, type: r.type})
          })
          localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {order: data, member: result.data[0]})))
          // 如果有退貨 ID (returnOrderID)，表示已退貨，不可以再修改訂單
          if (data.returnOrderID) {
            this.$router.push(`/returns/view/${data.returnOrderID}`)
          } else {
            this.$router.push(`/pos/modify/${data.orderID}`)
          }
        } else if (type === 'member') {
          // member 透過 item 更新資料
          _.map(item.saleDetails, r => {
            r.salesName = `(${r.staffID}) ${r.staffName}`
            return r
          })
          item.voucherEffectiveList = _.map(item.voucherEffectiveList, r => {
            return Object.assign({}, {voucherNumber: r.voucherNumber, type: r.type})
          })
          localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {order: item, member: result.data[0]})))
          if (item.returnOrderID) {
            this.$router.push(`/returns/view/${item.returnOrderID}`)
          } else {
            this.$router.push(`/pos/modify/${item.orderID}`)
          }
        }
      }
    },
    formatter (value) {
      return value ? `${value.toLocaleString()}` : 0
    }
  },
  computed: {
    queriedData() {
      let result = []
      if (this.searchedData.length > 0) {
        result = _.map(this.searchedData, 'item')
      } else {
        result = this.searchQuery.length === 0 ? this.tableByDay : []
      }
      return result.slice(this.from, this.to)
    },
    renderSummary () {
      let totalSaleAmount = _.chain(this.tableByDay)
        .cloneDeep()
        .sumBy('totalSaleAmount')
        .value()
      let member = _.chain(this.tableByDay)
        .groupBy('memberID')
        .keys()
        .cloneDeep()
        .value()
      let total = this.tableByDay.length
      return {totalSaleAmount, member: member.length, total}
    },
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss">
  .expand{
    padding: 12px 7px;
    vertical-align: middle;
  }
  .el-table__expanded-cell{
    padding: 20px 0px 20px 80px !important;
  }
</style>
