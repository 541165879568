<template>
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <h4 slot="header" class="card-title">商品列表</h4>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <button type="button" el="tooltip" class="btn btn-info btn-round" @click.prevent="newProduct" v-if="acl.productEdit">
              <i class="now-ui-icons ui-1_simple-add"></i>新增商品
            </button>
            <!--搜尋-->
            <fg-input class="ml-auto">
              <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search" placeholder="搜尋商品名稱或編號" v-model="searchQuery" aria-controls="datatables"></el-input>
            </fg-input>
          </div>
          <el-table stripe :data="queriedData" empty-text="暫無產品資料" height="750">
            <el-table-column fixed width="80" type="index" label="序"></el-table-column>
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
              <template slot-scope="props">
                <a href="#" v-if="column.formatter && acl.productEdit" @click.prevent="handleAction(props.$index, props.row, 'modify')">{{props.row[column.prop]}}</a>
                <div v-else>{{props.row[column.prop]}}</div>
              </template>
            </el-table-column>
            <el-table-column width="90" label="加入常用">
              <div slot-scope="props">
                <el-button class="outline-none" v-if="props.row.favo" icon="el-icon-star-on" type="info" circle @click.prevent="handleFavorite('remove', props.row.productUUID)"></el-button>
                <el-button class="outline-none"  v-else icon="el-icon-star-off" circle @click.prevent="handleFavorite('add', props.row.productUUID)"></el-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <!--顯示筆數設定-->
          <el-select class="select-primary" style="width: 200px" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <!--當前頁訊息-->
          <div><p class="card-category"> 顯示 {{ from + 1 }} 至 {{ to }} ，總共 {{ total }} 筆紀錄</p></div>
          <!--分頁-->
          <n-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </n-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button} from 'element-ui'
import { Pagination as NPagination } from 'src/components'
import { getLocalStorage } from 'src/util/index.js'
import API from 'src/api/index.js'
import Fuse from 'fuse.js'
import _ from 'lodash'

export default {
  components: {
    NPagination,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    queriedData() {
      let result = []
      if (this.searchedData.length > 0) {
        result = _.map(this.searchedData, 'item')
      } else {
        result = this.searchQuery.length === 0 ? this.tableData : []
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    }
  },
  data() {
    return {
      acl: {},
      pagination: {
        perPage: 150,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 150],
        total: 0
      },
      tableColumns: [
        {
          prop: 'SKU',
          label: '商品編號',
          minWidth: 80,
           formatter: 'link'
        },
        {
          prop: 'name',
          label: '商品名稱',
          minWidth: 180,
          formatter: 'link'
        },
        {
          prop: 'unit',
          label: '單位',
          minWidth: 60
        },
        {
          prop: 'category',
          label: '商品分類',
          minWidth: 80
        },
        {
          prop: 'defaultSellingPrice',
          label: '定價',
          minWidth: 70
        },
        {
          prop: 'staffPrice',
          label: '員工價',
          minWidth: 70
        },
        {
          prop: 'vipPrice',
          label: 'VIP價',
          minWidth: 70
        },
        {
          prop: 'friendPrice',
          label: '親友價',
          minWidth: 70
        }
      ],
      searchQuery: '',
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    async init () {
      let result = await API.getProduct()
      if (result.message === 'success') {
        this.tableData = _.map(result.data, r => {
          let staffPrice = _.find(r.priceBook, {id: 'staffPrice'})
          let vipPrice = _.find(r.priceBook, {id: 'vipPrice'})
          let friendPrice = _.find(r.priceBook, {id: 'friendPrice'})
          r.staffPrice = staffPrice.price !== null ? staffPrice.price : '-'
          r.vipPrice = vipPrice.price !== null ? vipPrice.price : '-'
          r.friendPrice = friendPrice.price !== null ? friendPrice.price : '-'
          return r
        })
      }
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ['name', 'SKU'],
        threshold: 0.1,
        findAllMatches: true
      })
    },
    newProduct () {
      this.$router.push('/forms/product/add')
    },
    async handleAction (index, row, type) {
      switch (type) {
        case 'modify':
          this.$router.push(`/forms/product/modify/${row.productUUID}`)
          break
      }
    },
    async handleFavorite (type, productUUID) {
      if (type === 'add') {
        let result = await API.addProductFavorite(productUUID)
        if (result.message === 'success') {
          this.init()
        }
      } else {
        let result = await API.removeProductFavorite(productUUID)
        if (result.message === 'success') {
          this.init()
        }
      }
    }
  },
  async mounted() {
    this.init()
    let localStorageData = getLocalStorage('chengyoData')
    if (_.get(localStorageData, 'acl', {})) {
      this.acl = localStorageData.acl
    }
  },
  watch: {
    searchQuery(value) {
      if (value !== '') {
        this.searchedData = this.fuseSearch.search(this.searchQuery).length === 0 ? [] : this.fuseSearch.search(this.searchQuery)
      } else {
        this.searchedData = []
      }
    }
  }
}
</script>
<style lang="scss">
  .outline-none{
    &:focus{
      outline: none !important;
    }
  }
</style>
