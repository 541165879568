<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar :title="auth.branchName || '澤予堂'">
      <template slot="links">
        <user-menu></user-menu>
        <sidebar-item :link="{name: '會員列表', icon: 'now-ui-icons business_badge', path: '/member'}" v-if="acl.member"></sidebar-item>
        <sidebar-item :link="{name: '商品列表', icon: 'now-ui-icons shopping_box', path: '/product'}" v-if="acl.product"></sidebar-item>
        <sidebar-item :link="{name: '當日訂單', icon: 'now-ui-icons files_single-copy-04', path: '/order'}" v-if="acl.order"></sidebar-item>
        <sidebar-item :link="{name: '關帳紀錄', icon: 'now-ui-icons education_agenda-bookmark', path: '/closeorder'}" v-if="acl.closeOrder"></sidebar-item>
        <sidebar-item :link="{name: '報表分析', icon: 'now-ui-icons files_paper'}">
          <sidebar-item :link="{name: '歷史報表紀錄', path: '/reporthistory'}" v-if="acl.reportHistory"></sidebar-item> 
          <sidebar-item :link="{name: '每日銷售明細', path: '/reportdaily'}" v-if="acl.reportDaily"></sidebar-item> 
          <sidebar-item :link="{name: '銷貨員商品銷售分析', path: '/reportsales'}" v-if="acl.reportSales"></sidebar-item> 
          <sidebar-item :link="{name: '回收券明細報表', path: '/reportvoucher'}" v-if="acl.reportVoucher"></sidebar-item>
          <sidebar-item :link="{name: '免掛券明細報表', path: '/reportcoupon'}" v-if="acl.reportCoupon"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '設定', icon: 'now-ui-icons loader_gear'}">
          <sidebar-item :link="{name: '登入帳號管理', path: '/accountlist'}" v-if="acl.auth"></sidebar-item>
          <sidebar-item :link="{name: '銷售人員管理', path: '/staff'}" v-if="acl.staff"></sidebar-item>
          <sidebar-item :link="{name: '產品群組管理', path: '/category'}"></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <router-view name="header"></router-view>
      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import { ZoomCenterTransition } from 'vue2-transitions'
import { getLocalStorage } from 'src/util/index.js'
import UserMenu from './Extra/UserMenu.vue'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    ZoomCenterTransition,
    UserMenu
  },
  data () {
    return {
      acl: {},
      auth: {}
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  },
  mounted() {
    let docClasses = document.body.classList
    let isWindows = navigator.platform.startsWith('Win')
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar')
      initScrollbar('sidebar-wrapper')
      docClasses.add('perfect-scrollbar-on')
    } else {
      docClasses.add('perfect-scrollbar-off')
    }
    let localStorageData = getLocalStorage('chengyoData')
    let keys = Object.keys(localStorageData)
    if (keys) {
      keys.forEach(key => {
        this[key] = localStorageData[key]
      })
    }
  }
}
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
