<template>
  <div class="user user__menu">
    <div class="photo">
      <img :src="image" alt="avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        href="#"
      >
        <span>
          {{ this.name }}
        </span>
      </a>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<script>

import { getLocalStorage } from 'src/util/index.js'
import _ from 'lodash'

export default {
  name: 'user-menu',
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: 'img/placeholder.jpg'
    }
  },
  data() {
    return {
      isClosed: true,
      name: ''
    }
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed
    }
  },
  mounted() {
    let localStorageData = getLocalStorage('chengyoData')
    if (_.get(localStorageData, 'auth', {})) {
      this.name = localStorageData.auth.name
    }
  }
}
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
