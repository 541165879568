import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
import AuthLayout from 'src/pages/Dashboard/Pages/AuthLayout.vue'
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
import DefaultHeader from 'src/pages/Dashboard/DefaultHeader'
import Member from 'src/pages/Dashboard/Member.vue'
import Product from 'src/pages/Dashboard/Product.vue'
import AccountList from 'src/pages/Dashboard/AccountList.vue'
import Staff from 'src/pages/Dashboard/Staff.vue'
import Category from 'src/pages/Dashboard/Category.vue'
import Order from 'src/pages/Dashboard/Order.vue'
import CloseOrder from 'src/pages/Dashboard/CloseOrder.vue'
import ReportHistory from 'src/pages/Dashboard/ReportHistory.vue'
import ReportMember from 'src/pages/Dashboard/ReportMember.vue'
import ReportDaily from 'src/pages/Dashboard/ReportDaily.vue'
import ReportSales from 'src/pages/Dashboard/ReportSales.vue'
import ReportVoucher from 'src/pages/Dashboard/ReportVoucher.vue'
import ReportCoupon from 'src/pages/Dashboard/ReportCoupon.vue'
// Pos pages
const PosHeader = () => import('src/pages/Dashboard/Dashboard/PosHeader.vue')
const Pos = () => import('src/pages/Dashboard/Dashboard/pos.vue')
const Returns = () => import('src/pages/Dashboard/Dashboard/returns.vue')
// Forms pages
const ProductForms = () => import('src/pages/Dashboard/Forms/ProductForms.vue')
const MemberForms = () => import('src/pages/Dashboard/Forms/MemberForms.vue')
const AuthorityForms = () => import('src/pages/Dashboard/Forms/AuthorityForms.vue')
const AccountForms = () => import('src/pages/Dashboard/Forms/AccountForms.vue')
const Login = () => import( 'src/pages/Dashboard/Pages/Login.vue')

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/product',
  name: 'Forms',
  children: [
    // 新增產品
    {
      path: 'product',
      name: 'Product Forms',
      components: { default: ProductForms, header: DefaultHeader },
      children: [
        {
          name: 'Product Modify',
          path: '/forms/product/:action/:id?',
          meta: {
            acl: 'product'
          }
        }
      ]
    },
    // 會員
    {
      path: 'member',
      name: 'Member Forms',
      components: { default: MemberForms, header: DefaultHeader },
      children: [
        {
          name: 'Member Modify',
          path: '/forms/member/:action/:id?',
          meta: {
            acl: 'member'
          }
        }
      ]
    },
    // 登入帳號
    {
      path: 'account',
      name: 'Account Forms',
      components: { default: AccountForms, header: DefaultHeader },
      children: [
        {
          name: 'Account Modify',
          path: '/forms/account/:action/:id?',
          meta: {
            acl: 'auth'
          }
        }
      ]
    },
    // 權限管理
    {
      path: 'authority',
      name: 'Authority',
      components: { default: AuthorityForms, header: DefaultHeader },
      children: [
        {
          name: 'Authority Modify',
          path: '/forms/authority',
          meta: {
            acl: 'auth'
          }
        }
      ]
    }
  ]
}

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
}

const routes = [
  {
    path: '/',
    redirect: '/member',
    name: 'Home'
  },
  formsMenu,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/member',
    name: 'Dashboard',
    children: [
      {
        path: 'pos',
        name: 'POS',
        components: { default: Pos, header: PosHeader },
        meta: {
          acl: 'member'
        },
        children: [
          {
            name: 'POS Modify',
            path: ':action/:id?',
            components: { default: Pos, header: PosHeader },
            meta: {
              acl: 'member'
            }
          }
        ]
      },
      {
        path: 'returns',
        name: 'Returns',
        components: { default: Returns, header: PosHeader },
        meta: {
          acl: 'member'
        },
        children: [
          {
            name: 'Returns Modify',
            path: ':action/:id?',
            components: { default: Returns, header: PosHeader },
            meta: {
              acl: 'member'
            }
          }
        ]
      },
      {
        path: 'member',
        name: 'Member',
        meta: {
          acl: 'member'
        },
        components: { default: Member, header: DefaultHeader },
      },
      {
        path: 'accountlist',
        name: 'Account',
        meta: {
          acl: 'auth'
        },
        components: { default: AccountList, header: DefaultHeader },
        children: [
          {
            path: ':action/:id?',
            meta: {
              acl: 'auth'
            }
          }
        ]
      },
      {
        path: 'staff',
        name: 'Staff',
        meta: {
          acl: 'staff'
        },
        components: { default: Staff, header: DefaultHeader }
      },
      {
        path: 'category',
        name: 'Category',
        meta: {
          acl: 'product'
        },
        components: { default: Category, header: DefaultHeader }
      },
      {
        path: 'product',
        name: 'Product',
        meta: {
          acl: 'product'
        },
        components: { default: Product, header: DefaultHeader }
      },
      {
        path: 'order',
        name: 'Order',
        meta: {
          acl: 'order'
        },
        components: { default: Order, header: DefaultHeader }
      },
      {
        path: 'closeOrder',
        name: 'CloseOrder',
        meta: {
          acl: 'closeOrder'
        },
        components: { default: CloseOrder, header: DefaultHeader }
      }, 
      {
        path: 'reporthistory',
        name: 'ReportHistory',
        meta: {
          acl: 'reportHistory'
        },
        components: { default: ReportHistory, header: DefaultHeader }
      },
      {
        path: 'reportmember',
        name: 'ReportMember',
        meta: {
          acl: 'reportHistory'
        },
        components: { default: ReportMember, header: DefaultHeader },
        children: [
          {
            path: ':id?',
            meta: {
              acl: 'reportHistory'
            }
          }
        ]
      },
      {
        path: 'reportdaily',
        name: 'ReportDaily',
        meta: {
          acl: 'reportDaily'
        },
        components: { default: ReportDaily, header: DefaultHeader }
      },
      {
        path: 'reportsales',
        name: 'ReportSales',
        meta: {
          acl: 'reportSales'
        },
        components: { default: ReportSales, header: DefaultHeader }
      }, 
      {
        path: 'reportvoucher',
        name: 'ReportVoucher',
        meta: {
          acl: 'reportVoucher'
        },
        components: { default: ReportVoucher, header: DefaultHeader }
      },
      {
        path: 'reportcoupon',
        name: 'ReportCoupon',
        meta: {
          acl: 'reportCoupon'
        },
        components: { default: ReportCoupon, header: DefaultHeader }
      }
    ]
  },
  { path: '*', component: NotFound }
]

export default routes
