<template>
<div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <h4 slot="header" class="card-title">會員列表</h4>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <button type="button" el="tooltip" class="btn btn-info btn-round" @click.prevent="handleAction(null, null, 'add')">
              <i class="fa fa-address-card"></i> 新增會員
            </button>
            <!--搜尋-->
            <div class=" d-flex justify-content-end">
              <fg-input class="m-0 align-self-center">
                <el-input type="search" class="m-0" ref="searchMember" prefix-icon="el-icon-search" placeholder="請搜尋顧客姓名" v-model="searchQuery" aria-controls="datatables" @clear="clearSearch" @keyup.enter.native="searchMember" focus></el-input>
              </fg-input>
              <n-button type="primary" size="sm" class="btn-round" @click.native="searchMember"><i class="now-ui-icons ui-1_zoom-bold"></i> 搜尋</n-button>
            </div>
          </div>
          <el-table stripe style="width: 100%" :data="queriedData" empty-text="暫無會員資料">
            <el-table-column type="index" width="95" label="序" sortable fixed></el-table-column>
            <el-table-column v-for="column in tableColumns" :key="column.label" :prop="column.prop" :label="column.label" sortable fixed>
              <template v-if="column.formatter" slot-scope="props">
                <a href="#" @click.prevent="handleAction(props.$index, props.row, 'modify')">{{props.row[column.prop]}}</a>
              </template>
            </el-table-column>
            <el-table-column :min-width="250" fixed="right" label="操作">
              <div slot-scope="props" class="table-actions">
                <n-button type="default" @click.native="handleAction(props.$index, props.row, 'record')" class="mx-2 btn btn-round">
                  <i class="now-ui-icons emoticons_satisfied"></i>
                  <span> 體重紀錄</span>
                </n-button>
                <n-button type="primary" @click.native="handleAction(props.$index, props.row, 'payment')" class="mx-2 btn btn-round">
                  <i class="now-ui-icons business_money-coins"></i>
                  <span> 前往結帳</span>
                </n-button>
                <n-button type="warning" @click.native="handleAction(props.$index, props.row, 'report')" class="mx-2 btn btn-round" v-if="acl.reportHistory">
                  <i class="now-ui-icons files_single-copy-04"></i>
                  <span> 交易紀錄</span>
                </n-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div>
            <p class="card-category"> 顯示 {{ from + 1 }} 至 {{ to }} ，總共有 {{ total }} 紀錄</p>
          </div>
          <n-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" @nextPage="nextPage" @changePage="changePage" @prevPage="prevPage"></n-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import {getLocalStorage} from 'src/util/index.js'
import { Table, TableColumn, Select, Option, Button} from 'element-ui'
import { Pagination as NPagination } from 'src/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    NPagination,
    [Button.name]: Button,
    [Table.name]: Table,
    [Select.name]: Select,
    [Option.name]: Option,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      data: {
        consultation: []
      },
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      tableColumns: [
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 150,
          formatter: 'link'
        },
        {
          prop: 'name',
          label: '姓名',
          minWidth: 200,
          formatter: 'link'
        }
      ],
      acl: {},
      staff: [],
      searchedData: [],
      searchQuery: '',
      tableData: [],
      fuseSearch: null
    }
  },
  methods: {
    async init () {
      let result = await API.getStaff()
      if (result.message === 'success') {
        this.staff = result.data
      }
    },
    async searchMember () {
      let obj = {searchKey: this.searchQuery}
      let result = await API.searchMember(JSON.stringify(obj))
      if (result.message === 'success' & result.totalData >= 1) {
        this.searchedData = result.data
      } else {
        this.searchedData = []
      }
    },
    clearSearch () {
      this.searchedData = []
    },
    async handleAction(index, row, type) {
      let localStorageData = getLocalStorage('chengyoData')
      switch (type) {
        case 'add':
          this.$router.push('/forms/member/add')
          break
        case 'modify':
          if (localStorageData) {
            localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {member: row})))
          }
          this.$router.push(`/forms/member/modify/${row.id}`)
          break
        case 'payment': {
          let result = await API.getMemberConsultation(row.id)
          if (result.message === 'success') {
            let data = _.reverse(_.get(result, 'data', []))
            if (data.length >= 1) {
              data[0].lastDate = moment(data[0].date, 'X').format('YYYY/MM/DD hh:mm:ss') 
              row.consultation = data[0]
            }
          }
          if (localStorageData) {
            localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {member: row})))
          }
          this.$router.push('/pos')
          break
        }
        case 'record': {
          let result = await API.getMemberConsultation(row.id)
          if (result.message === 'success') {
            let consultation = _.reverse(_.get(result, 'data', []).map(r => r))
            let today = moment().format('YYYY-MM-DD')
            Swal.fire({
              title: `將紀錄 <span class="text-primary"> ${row.name} </span> 的體重`,
              html: `<div class="form-horizontal">
                      <div>前次體重記錄為<span class="text-primary">  ${_.get(consultation, '[0]bodyWeight', 0)} </span> ${_.get(consultation, 'unit', 'Kg')}</div>
                      <br/>
                      <div class="form-group">
                        <div class="row">
                          <label class="col-md-4 col-form-label">本次體重紀錄</label>
                          <div class="col-md-8">
                            <input id="body-weight" type="number" min="0" placeholder="請輸入體重" class="form-control"/>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <label class="col-md-4 col-form-label">紀錄日期</label>
                          <div class="col-md-8">
                            <input id="date" type="date" value="${today}" class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <label class="col-md-4 col-form-label">備註</label>
                          <div class="col-md-8">
                            <input id="note" type="text" placeholder="請輸入備註" class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <label class="col-md-4 col-form-label">紀錄人員</label>
                          <div class="col-md-8">
                            <select id="staff-recordID" class="form-control">
                              ${this.mappingStaff.map(option => `<option value="${option.staffID}">${option.mappingName}</option>`)}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <label class="col-md-4 col-form-label">紀錄醫生</label>
                          <div class="col-md-8">
                            <select id="doctor-recordID" class="form-control ">
                              ${this.mappingDoctor.map(option => `<option value="${option.staffID}">${option.mappingName}</option>`)}
                            </select>                    
                          </div>
                        </div>
                      </div>
                    </div>`,
              buttonsStyling: false,
              confirmButtonText: '<i class="fa fa-save"></i> 確認送出',
              confirmButtonClass: 'btn btn-primary btn-lg btn-round btn-block',
              preConfirm: async () => {
                let data = {}
                let doctorID = document.getElementById('doctor-recordID').value
                let staffID = document.getElementById('staff-recordID').value
                let date = document.getElementById('date').value
                data = {
                  memberID: row.id,
                  bodyWeight: Number(document.getElementById('body-weight').value),
                  date: moment(date).format('X'),
                  note: document.getElementById('note').value,
                  doctorID,
                  doctorName: _.find(this.staff, {staffID: doctorID}).name, 
                  staffID,
                  staffName:  _.find(this.staff, {staffID: staffID}).name,
                  unit: 'kg'
                }
                let result = await API.createMemberConsultation(JSON.stringify(data))
                if (result.message === 'success') {
                  Swal.fire({
                    title: `更新 ${row.name} 資料成功`,
                    icon: 'success'
                  })
                } else {
                  Swal.fire({
                    title: `更新 ${row.name} 資料失敗，請洽系統管理員`,
                    icon: 'error'
                  })
                }
              }
            })
          }
          break
        }
        case 'report':
          if (localStorageData) {
            localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {member: row})))
          }
          this.$router.push(`/reportmember/${row.memberID}`)
          break
      }
    },
    async changePage (page) {
      let result = await API.getMember(page === 1 ? 0 : (page - 1) * this.pagination.perPage, this.pagination.perPage)
      if (result.message === 'success') {
        this.tableData = result
      }
    },
    async prevPage (page) {
      let result = await API.getMember(page === 1 ? 0 : (page - 1) * this.pagination.perPage, this.pagination.perPage)
      if (result.message === 'success') {
        this.tableData = result
      }
    },
    async nextPage (page) {
      let result = await API.getMember(page === 1 ? 0 : (page - 1) * this.pagination.perPage, this.pagination.perPage)
      if (result.message === 'success') {
        this.tableData = result
      }
    }
  },
  computed: {
    queriedData() {
      let result = []
      if (this.searchedData.length > 0) {
        result = this.searchedData
      } else {
        result = _.get(this.tableData, 'data', [])
      }
      return result
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      // 計算 total
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.totalData
    },
    mappingStaff () {
      return _.chain(this.staff)
        .cloneDeep()
        .map(r => {
          r.mappingName = `( ${r.staffID} ) ${r.name}`
          return r
        })
        .filter(r => r.type.shortCode === 'S')
        .value()
    },
    mappingDoctor () {
      return _.chain(this.staff)
        .cloneDeep()
        .map(r => {
          r.mappingName = `( ${r.staffID} ) ${r.name}`
          return r
        })
        .filter(r => r.type.shortCode === 'D')
        .value()
    }
  },
  async mounted () {
    let localStorageData = getLocalStorage('chengyoData')
    if (!localStorageData) {
      localStorage.setItem('chengyoData', JSON.stringify(Object.assign({member: {}})))
    }
    if (_.get(localStorageData, 'acl', {})) {
      this.acl = localStorageData.acl
    }
    let result = await API.getMember(0, this.pagination.perPage)
    if (result.message === 'success') {
      this.tableData = result
    }
    this.fuseSearch = new Fuse(_.get(this.tableData, 'data', []), {
      keys: ['name', 'birthday', 'memberID'],
      threshold: 0.3
    })
    this.$nextTick(()=> {
      this.$refs.searchMember.focus()
    })
    this.init()
  }
}
</script>
<style></style>
