<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <!-- <a class="navbar-brand" href="#pablo">
        {{ $route.name }}
      </a> -->
    </div>
    <button
      @click="toggleNavbar"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template slot="navbar-menu">

      <ul class="navbar-nav">

        <drop-down tag="li" position="right" class="nav-item" icon="now-ui-icons location_world">
          <a class="dropdown-item" href="#" @click.prevent="logout">登出</a>
          <div class="dropdown-item">v1.4.10</div>
        </drop-down>
        <li class="nav-item">
          <a class="nav-link">
            <i class="now-ui-icons users_single-02"></i>
            <p>開帳人員：{{closeStaff}}
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from 'src/components'
import API from 'src/api/index.js'
import {getLocalStorage} from 'src/util/index.js'

export default {
  components: {
    Navbar,
    NavbarToggleButton
  },
  computed: {
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    },
    closeOrderStaff () {
      return this.closeStaff
    },
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      closeStaff: ''
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    async $route() {
      this.init()
    }
  },
  methods: {
    async init () {
      let result = await API.checkCloseAccount()
      if (result.message === 'success') {
        this.closeStaff = Object.keys(result.data).length >= 1 ? `(${result.data.openStaff.staffID})${result.data.openStaff.name}` : '尚無開帳人員'
      }
    },
    logout () {
      let localStorageData = getLocalStorage('chengyoData')
      if (localStorageData.token) {
        localStorage.removeItem('chengyoData')
        this.$router.push('/login')
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    }
  }
}
</script>
<style></style>
