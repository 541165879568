<template>
  <div class="row">
    <div class="col-12">
      <card class="close-order" card-body-classes="row" no-footer-line>
        <h4 slot="header" class="card-title">關帳紀錄</h4>
        <ul class="col-2 p-0 list-item-container">
          <li v-for="item in closerOrders" :key="item.openTime" class="p-2 list-item-detail" @click="showCloseOrderList(item.openTime)" style="list-style-type: none;">
            <div class="d-flex justify-content-between my-2">
              <div class="font-12 font-weight-bolder">{{formatter(item.openTime)}}<span v-if="item.status === 'enforced'" class="el-tag el-tag--danger el-tag--small el-tag--light p-1 mx-1 my-0">強制關帳</span></div>
              <small style="color: #a0a0a0;" v-if="item.totalDataCount">{{item.totalDataCount}} 筆</small>
            </div>
          </li>
        </ul>
        <div class="col-10">
          <fg-input class="text-right">
            <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search" style="width: 400px" placeholder="搜尋交易編號、會員編號、會員姓名" v-model="searchQuery" aria-controls="datatables"></el-input>
          </fg-input>
          <el-table :data="queriedData" header-cell-clas-name="font-14" :row-class-name="rendertableRowClassName">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable>
              <template slot-scope="scope">
                <div v-if="column.prop === 'tradeID'">
                  <el-button type="text" class="outline-none" style="color: #f96332;" @click.prevent="showOrderDetails(scope)">{{ scope.row[column.prop] }}</el-button>
                </div>
                <div v-else-if="column.prop === 'totalSaleAmount'">
                  ${{ fc(scope.row[column.prop] )}}
                </div>
                <div v-else>{{ scope.row[column.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Upload, Button, Form, Loading } from 'element-ui'
import { getLocalStorage, renderPaymentType, renderStoreType } from 'src/util/index.js'
import moment from 'moment'
import Fuse from 'fuse.js'
import _ from 'lodash'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload,
    [Button.name]: Button,
    [Form.name]: Form
  },
  data() {
    return {
      tableColumns: [
        {
          prop: 'tradeDate',
          label: '交易日期',
          minWidth: 150
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 120
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 100
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 80
        },
        {
          prop: 'totalSaleAmount',
          label: '金額',
          minWidth: 80
        },
        {
          prop: 'mappingPayment',
          label: '付款方式',
          minWidth: 80
        },
        {
          prop: 'branchID',
          label: '分店編號',
          minWidth: 80
        }
      ],
      searchQuery: '',
      searchedData: [],
      fuseSearch: null,
      closerOrders: [],
      orderDetails: []
    }
  },
  watch: {
    searchQuery(value) {
      if (value !== '') {
        this.searchedData = this.fuseSearch.search(this.searchQuery).length === 0 ? [] : this.fuseSearch.search(this.searchQuery)
      } else {
        this.searchedData = []
      }
    }
  },
  methods: {
    async init() {
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let result = await API.getCloseOrder()
      this.closerOrders = result.data
      this.showCloseOrderList(result.data[0].openTime)
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    async searchMember (data) {
      let obj = {searchKey: data}
      let result = await API.searchMember(JSON.stringify(obj))
      if (result.message === 'success') {
        let consultation = await API.getMemberConsultation(result.data[0].id)
        result.data[0].consultation = _.chain(consultation.data)
          .reverse()
          .map(r => {
            r.lastDate = moment(r.date, 'X').format('YYYY-MM-DD')
            return r
          })
          .head()
          .value()
        return result
      }
    },
    async showCloseOrderList (value) {
      this.searchQuery = ''
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let result = await API.getCloseOrder(value)
      this.orderDetails = _.map(result.data, r => {
        r.tradeDate = moment(r.tradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
        r.mappingPayment = renderPaymentType(r.payment)
        r.branchID = renderStoreType(r.branchID)
        return r
      })
      this.$nextTick(() => {
        loadingInstance.close()
      })
      this.fuseSearch = new Fuse(this.orderDetails, {
        keys: ['tradeID', 'memberName', 'memberID'],
        threshold: 0.1,
        includeScore: true
      })
    },
    async showOrderDetails (data) {
      let localStorageData = getLocalStorage('chengyoData')
      let result = await this.searchMember(data.row.memberID)
      if (localStorageData && result) {
        _.map(data.row.saleDetails, r => {
          r.memberType = data.row.memberType
          r.originQty = r.qty
          r.originSalePrice = r.salePrice
          r.originRewardPrice = _.get(r, 'rewardPrice', 0)
          // 報表因為是row data，需要參考折扣價格計算總價，無法列出單價，金額不是單純的數量*單價
          let count = r.qty / r.quantityDiscout
          r.mappingPrice = (count >= 1 && r.quantityDiscoutPrice) ? r.quantityDiscoutPrice : r.salePrice
          if (count >= 1) {
            r.totalAmount = Math.floor(count) * Number(r.mappingPrice) + (r.qty % r.quantityDiscout * Number(r.salePrice))
          } else {
            r.totalAmount = r.qty * Number(r.salePrice)
          }
          r.salesName = `(${r.staffID}) ${r.staffName}`
          return r
        })
        this.renderReturnRouter(data)
        localStorage.setItem('chengyoData', JSON.stringify(Object.assign(localStorageData, {order: data.row, member: result.data[0]})))
      }
    },
    renderReturnRouter(data) {
      if (data.row.returnOrderID) {
        this.$router.push(`/returns/view/${data.row.returnOrderID}`) 
      } else {
        this.$router.push('/returns')
      }
    },
    rendertableRowClassName ({row}) {
      if (_.get(row, 'returnOrderID', null)) {
        return 'table-danger'
      }
    },
    formatter (value) {
      return moment(value, 'X').format('YYYY/MM/DD')
    },
    fc (value) {
      return `${value.toLocaleString()}`
    }
  },
  computed: {
    queriedData () {
      let result = []
      if (this.searchedData.length > 0) {
        result = _.compact(_.map(this.searchedData, r => {
          if (r.score !== 0.1) {
            return r.item
          }
        }))
      } else {
        result = this.searchQuery.length === 0 ? this.orderDetails : []
      }
      return result.slice(this.from, this.to)
    },
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss">
  .expand{
    padding: 12px 7px;
    vertical-align: middle;
  }
  .el-table__expanded-cell{
    padding: 20px 0px 20px 80px !important;
  }
  .close-order{
    .container {
      max-height: calc(100vh - 135px - 72px);
      height: calc(100vh - 135px - 72px);
      overflow: hidden;
    }
  
    .list-item-detail {
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;
      &:hover {
        background-color: #f5f8f9;
      }
    }
    .list-item-container {
      border-right: 1px solid #e6e6e6;
    }
  }
</style>
