<template>
<div>
  <div class="card card-stats card-raised">
    <div class="card-body">
      <div class="row">
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-primary">
              <i class="now-ui-icons business_money-coins"></i>
            </div>
            <h3 class="info-title">
              <animated-number :value="totalVoucher" :formatValue="formatter" :duration="500"></animated-number><small> 張</small>
            </h3>
            <h6 class="stats-title">期間免掛券總數</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-info">
              <i class="now-ui-icons users_single-02"></i>
            </div>
            <h3 class="info-title">
              <animated-number :value="renderSummary.member" :formatValue="formatter" :duration="500"></animated-number><small> 人</small>
            </h3>
            <h6 class="stats-title">回診會員總數，期間新增 ({{todayNewMember}}人）</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-default">
              <i class="now-ui-icons files_paper"></i>
            </div>
            <h3 class="info-title">
              <animated-number :value="renderSummary.orderTotal" :formatValue="formatter" :duration="500"></animated-number><small> 筆</small>
            </h3>
            <h6 class="stats-title">期間訂單總數</h6>
          </div>
        </div>
      </div>
      
      <div class="col-md-3">
        <div class="statistics">
          <div class="info">
            <div class="icon icon-default">
              <i class="now-ui-icons ui-1_calendar-60"></i>
            </div>
            <h3 class="info-title">
              <div class="justify-content-end">
                <el-date-picker
                  v-model="datePicker"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="開始日期"
                  end-placeholder="結束日期"
                  :picker-options="pickerOptions"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="timestamp"
                  @change="handleChange"
                >
                </el-date-picker>
              </div>
            </h3>
            <h6 class="stats-title"> 選擇查詢期間</h6>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width" no-footer-line>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 slot="header" class="card-title">免掛券明細報表</h4>
          
          <fg-input class="mx-3 mt-4 text-right">
            <el-input type="search" class="mb-3" clearable prefix-icon="el-icon-search" style="width: 400px" placeholder="搜尋會員編號、會員姓名、免掛券起號，免掛券迄號" v-model="searchQuery" aria-controls="datatables"></el-input>
          </fg-input>

          <div class="align-self-center">
            <div class="d-flex justify-content-end">
              <button class="btn btn-info" @click.prevent="exportExcel" :disabled="computedExportDisabled"><i class="now-ui-icons arrows-1_cloud-download-93"></i> 匯出免掛券明細報表</button>
            </div>
          </div>
        </div>
        <!-- 歷史報表 -->
        <div class="col-12">
          <el-table stripe header-cell-class-name="font-14" :data="queriedData">
            <el-table-column v-for="column in tableByDayCols" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" sortable></el-table-column>
          </el-table>
        </div>
      </card>
    </div>
  </div>
</div>
</template>
<script>
import API from 'src/api/index.js'
import { Table, TableColumn, Select, Option, Button, DatePicker, Loading} from 'element-ui'
import { renderStoreType, getLocalStorage, setLocalStorage } from 'src/util/index.js'
import lang from 'element-ui/lib/locale/lang/zh-TW'
import { AnimatedNumber } from 'src/components'
import locale from 'element-ui/lib/locale'
import Fuse from 'fuse.js'
import moment from 'moment'
import XLSX from 'xlsx'
import _ from 'lodash'

locale.use(lang)

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    AnimatedNumber
  },
  watch: {
    searchQuery(value) {
      if (value !== '') {
        this.searchedData = this.fuseSearch.search(this.searchQuery).length === 0 ? [] : this.fuseSearch.search(this.searchQuery)
      } else {
        this.searchedData = []
      }
    }
  },
  data() {
    return {
      tableByDayCols: [
        {
          prop: 'effectiveDate',
          label: '回收日期',
          minWidth: 150
        },
        {
          prop: 'tradeID',
          label: '交易單號',
          minWidth: 120
        },
        {
          prop: 'memberID',
          label: '會員編號',
          minWidth: 100
        },
        {
          prop: 'memberName',
          label: '會員姓名',
          minWidth: 100
        },
        {
          prop: 'voucherQty',
          label: '數量',
          minWidth: 80
        },
        {
          prop: 'voucherNumberStart',
          label: '票券號碼(起)',
          minWidth: 120
        },
        {
          prop: 'voucherNumberEnd',
          label: '票券號碼（迄）',
          minWidth: 120
        },
        {
          prop: 'closeTradeDate',
          label: '帳務日期',
          minWidth: 150
        },
        {
          prop: 'branchID',
          label: '營業地點',
          minWidth: 100
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一週',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick',[start.setHours(0, 0, 0), end.setHours(23, 59, 59)])
            }
          }
        ]
      },
      todayNewMember: 0,
      totalVoucher: 0,
      searchQuery: '',
      searchedData: [],
      tableData: [],
      tableByDay: [],
      staff: [],
      datePicker: [],
      fuseSearch: null
    }
  },
  methods: {
    init () {
      let localStorageData = getLocalStorage('chengyoData')
      if (_.values(_.get(localStorageData, 'datePicker', {})).length > 0) {
        this.datePicker.push(localStorageData.datePicker.startTime)
        this.datePicker.push(localStorageData.datePicker.endTime)
        this.handleChange()
      }
    },
    async handleChange () {
      if (this.datePicker === null) {
        this.tableByDay = []
        setLocalStorage('chengyoData', 'datePicker', {})
        return
      }
      let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(255, 255, 255, 0.8)' })
      let startTime = moment(this.datePicker[0]).format('X')
      let endTime = moment(this.datePicker[1]).format('X')
      let obj = {startTime, endTime, type: 'coupon'}
      let result = await API.getReportVoucher(JSON.stringify(obj))
      if (result.message === 'success') {
        this.todayNewMember = _.get(result, 'todayNewMember', 0),
        this.totalVoucher = result.totalVoucher
        this.tableByDay = _.map(result.data, r => {
          r.effectiveDate = moment(r.effectiveDate, 'X').format('YYYY/MM/DD hh:mm:ss')
          r.closeTradeDate = moment(r.closeTradeDate, 'X').format('YYYY/MM/DD hh:mm:ss')
          r.branchID = renderStoreType(r.branchID) 
          return r
        })
      }
      this.fuseSearch = new Fuse(this.tableByDay, {
        keys: ['memberName', 'memberID', 'voucherNumberStart', 'voucherNumberEnd'],
        threshold: 0.1,
        findAllMatches: true
      })
      setLocalStorage('chengyoData', 'datePicker', {startTime: this.datePicker[0], endTime: this.datePicker[1]})
      this.$nextTick(() => {
        loadingInstance.close()
      })
    },
    exportExcel () {
      let title = '免掛券明細報表'
      let sheetList = ['免掛券明細報表']
      let wb = XLSX.utils.book_new()
      let startTime = moment(this.datePicker[0], 'x').format('YYYY-MM-DD')
      let endTime = moment(this.datePicker[1], 'x').format('YYYY-MM-DD')
      _.forEach(sheetList, name => {
        if (name === '免掛券明細報表') {
          let ws, resolveData
          let data = _.map(this.tableByDay, r => {
            let { effectiveDate, memberID, memberName, voucherQty, voucherNumberStart, voucherNumberEnd, tradeID,  closeTradeDate, branchID } = r
            return { effectiveDate, memberID, memberName, voucherQty, voucherNumberStart, voucherNumberEnd, tradeID, closeTradeDate, branchID }
          })
          let header = [{effectiveDate: '回收日期', tradeID: '交易單號',memberID: '會員編號', memberName: '會員姓名', voucherQty: '數量', voucherNumberStart: '票券號碼(起)', voucherNumberEnd: '票券號碼（迄）', closeTradeDate: '帳務日期', branchID: '營業地點名稱'}]
          resolveData = header.concat(data)
          ws = XLSX.utils.json_to_sheet(resolveData, {skipHeader: true, range: 1})
          XLSX.utils.book_append_sheet(wb, ws, name)
        }
      })
      XLSX.writeFile(wb, title + `(${startTime} - ${endTime})`  + '.xlsx')
    },
    formatter (value) {
      return `${value.toLocaleString()}`
    }
  },
  computed: {
    computedExportDisabled () {
      return !this.datePicker || this.datePicker.length === 0
    },
    queriedData() {
      let result = []
      if (this.searchedData.length > 0) {
        result = _.map(this.searchedData, 'item')
      } else {
        result = this.searchQuery.length === 0 ? this.tableByDay : []
      }
      return result.slice(this.from, this.to)
    },
    renderSummary () {
      let orderTotal = _.chain(this.tableByDay)
        .cloneDeep()
        .groupBy('tradeID')
        .keys()
        .value()
      let member = _.chain(this.tableByDay)
        .groupBy('memberID')
        .keys()
        .value()
      return {orderTotal: orderTotal.length, member: member.length, voucherTotal: this.tableByDay.length}
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style></style>
